import { useMemo,useState,useEffect } from 'react';
import { useSelector } from "react-redux";
import { url } from "../../services/api";
import { apiConnector } from "../../services/apiConnector";
import { useNavigate } from 'react-router-dom';
// import toast from "react-hot-toast";
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';

//Material UI Imports
import {
  Box,
  // Button,
  ListItemIcon,
  MenuItem,
  // Typography,
  // lighten,
} from '@mui/material';

import { AccountCircle, 
  // Send
 } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

 
const TaskCompo = ({allorMytask}) => {
  const navigate = useNavigate();



  const columns = useMemo(
    () => [
      {
        accessorFn: (row)=>  employee.role ==='admin' ? row.accountDetails?.companyName : allorMytask==='mytasks'? row.service?.accountId?.ghostName : row.accountDetails?.ghostName   ,
        id:'companyName',
        //accessorKey:'updatedBy.name',
        header:'Company Name',
        enableEditing: false,
        Edit: () => null,
        size:20,
      }, 
      {
        accessorKey: 'taskSubject',  
         header: 'Subject',
        size: 20,
      },
      {
        accessorKey: 'destinationsDetails.name',  
         header: 'Destination',
        size: 20,
      },
      {
        accessorKey: 'taskStatus',  
        header: 'Status',
        size: 10,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() ==='PENDING'  ? theme.palette.error.dark
                  : cell.getValue() ==='DONE' ? theme.palette.success.dark: theme.palette.warning.dark  ,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
           { cell.getValue()}
          </Box>
        ),
        
      },

      {
        accessorKey: 'service.testingStatus', 
         header: 'Route Status',
         Cell: ({ cell,row }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() ==='FAILED'  ? theme.palette.error.dark
                  : cell.getValue() ==='PASSED' ? theme.palette.success.dark: theme.palette.warning.dark  ,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
           { cell.getValue() ==='ASSIGNED_TO_NOC'? 'IN_PROGRESS':  `${cell.getValue() } `}
          </Box>
        ),
         
       },

      {
        accessorFn: (row)=> row.assignedTo?.name   ,
        id:'AssignedTo',
        header: 'AssignedTo',
        size: 10,
      },
      {
        accessorKey: 'priority',//'assignedTo?._name', : (row) =>  row?.original?.priority === "1"?"HIGH": row?.original?.priority === 2 ? "MEDIUM" : row?.original?.priority === 3?"LOW":"D", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        header: 'Priority',
        size: 30,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() ===3  ? theme.palette.error.dark
                  : cell.getValue() ===2     ? theme.palette.warning.dark : theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
           { cell.getValue() === 1?"HIGH":cell.getValue()=== 2 ? "MEDIUM" :cell.getValue() === 3?"LOW":"D"}
          </Box>
        ),

      },


      {
        accessorFn: (row)=> row.createdBy?.name   ,
        id:'createdBy',
        header:'CreatedBy',
        enableEditing: false,
        Edit: () => null,
        size:20,
      },

      {
        accessorFn: (row) => new Date(row.createdAt), //convert to Date for sorting and filtering
        id: 'Created_At',
        header: 'Created Date',
        enableEditing: false,
        Edit: () => null,
        filterVariant: 'datetime-range',
        Cell: ({ cell }) => `${cell.getValue().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })} ${cell
          .getValue()
          .toLocaleTimeString()}`,//cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        muiFilterTextFieldProps: {
          sx: {
            minWidth: '150px',
          },
        },
      },
      {
        accessorFn: (row)=> row.updatedBy?.name   ,
        id:'updatedBy',
        //accessorKey:'updatedBy.name',
        header:'UpdatedBy',
        enableEditing: false,
        Edit: () => null,
        size:20,
      }, 

      {
        accessorFn: (row) => new Date(row.updatedAt), //convert to Date for sorting and filtering
        id: 'Updated_At',
        header: 'Updated Date',
        enableEditing: false,
        Edit: () => null,
        filterVariant: 'datetime-range',
        Cell: ({ cell }) => `${cell.getValue().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })} ${cell
          .getValue()
          .toLocaleTimeString()}`,//cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        muiFilterTextFieldProps: {
          sx: {
            minWidth: '150px',
          },
        },
      },
      
    ],
    [],
  );



  // useState vars
  const [allAccounts, setAllAccounts] = useState([]);
  const employee = useSelector((state) => state.employee);

  // const[empGroupType,setEmpGroupType]=useState("");
  // const[activeNOCList,setActiveNOCList]=useState([]);
  // const[selectedEmpList,setSelectedEmpLIst] = useState([]);
  // const[loadComponent,SetLoadComponent]=useState(false);

  // const modalCloseButtonRef = useRef();
  // const formResetRef = useRef();
  


 
  const headers = {
      Authorization: `Bearer ${employee.token}`,
    };

  useEffect(() => { 
   
    let condUrl="";
    if(allorMytask==='mytasks'){
      condUrl= `${url}/api/taskByEmp/${employee._id}` 
    }else if(allorMytask==='tasks'){
      condUrl= `${url}/api/task`;
    }
     
      const params = {
        method: "GET",
        url: condUrl,
        headers: headers,
      };

      if(params.url!==""){
      apiConnector(params)
        .then((response) => {
           //console.log("---> ",response.data)
              setAllAccounts(response.data);
        })
        .catch((error) => {
          console.error("Error fetching daily progress reports:", error);
          // setLoading(false);
        });
    }

    }, []);

  const table = useMaterialReactTable({
    columns,
    data:allAccounts, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,//showing multiple filter options
    enableColumnOrdering: true,
    enableGrouping: false,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true, // view profile others
    enableRowSelection: false,
    initialState: {
      density: 'compact',
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },

    renderRowActionMenuItems: ({ closeMenu,row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          
          navigate(`/taskTimeline?serviceId=${row?.original?.service?._id}`);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
           View Task Thread Group 
      </MenuItem>,
     
    ],

 

  });





  return  (  
    <>  
    <MaterialReactTable table={table} />
    </> );
};


const ExampleWithLocalizationProvider = () => (


  <LocalizationProvider dateAdapter={AdapterDayjs}   >
     <br/>   <br/>  
      <h3 className='h3 text-center text-black font-bold'> Task assigned to you</h3>
      <br/>  
    <TaskCompo allorMytask={"mytasks"} /> 
    <br/>   <br/>  
    <h3 className='h3 text-center text-black font-bold'> All Task </h3>
    <br/>  
    <TaskCompo allorMytask={"tasks"} /> 



  </LocalizationProvider>
);

export default ExampleWithLocalizationProvider;




 