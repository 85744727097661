import React, { useEffect, useState,useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation  } from 'react-router-dom';
import Sidebar from "../Components/Sidebar";
import { apiConnector } from "../../services/apiConnector";
import Loader from "../../components/loader/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import {url} from '../../services/api'
import {utils} from '../../services/utils'
import './timeline.css';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import DropzoneCompo from "../Components/DropzoneCompo";
import EmployeeDetailsDialog from "../Components/reusable/EmployeeDetailsDialog";
// import { Box, Paper, Typography, Button, Grid, IconButton } from '@mui/material';

// function ConfirmationModal({ message, onAccept, onReject, onCancel }) {
//   return (
//     <div className="">
//       <p>{message}</p>
//       <button onClick={onAccept}>Accept</button>
//       <button onClick={onReject}>Reject</button>
//       <button onClick={onCancel}>Cancel</button>
//     </div>
//   );
// }


const TaskTimeline = () => {

  //getting id from url query parameter
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('serviceId');

  const closeRefTask = useRef();
  const closeRefThread = useRef();
  const reqForRef =useRef();
  const resetFormTask=useRef();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [accountData,setAccountData]=useState({});
  const [serviceData,setServiceData]=useState({});
  const [taskData,setTaskData]=useState({});
  const [threadData,setThreadData] = useState([]);
  const [nocData,setNOCData]=useState([]);
  const [nocGroupData,setNOCGroupData]=useState([]);

  

  const [refresh,setRefresh]=useState(false);

  const [editTask ,setEditTask]=useState(false);

  //for files upload
  const [formData, setFormData] = useState({
          threadSubject: '',  threadMessage:'',});

  const[showIndiGroup,setShowIndiGroup]=useState("indi");
   //setting employee from state
  const employee = useSelector((state) => state.employee);
  const headers = {
        Authorization: `Bearer ${employee.token}`,
      };

const headersForFile = {
        Authorization: `Bearer ${employee.token}`,
        'Content-Type': 'multipart/form-data',
      };

  const [taskFiles,setTaskFiles]=useState([]);
  const [threadFiles,setThreadFiles]=useState([]);

  // const [taskFilesEdit,setTaskFilesEdit]=useState([]);

  const { acceptedFiles, getRootProps, getInputProps  } = useDropzone({
      //  accept: ['.pdf', '.csv', '.doc', '.docx', '.xls', '.xlsx'],
        multiple: true,
        onDrop: (acceptedFiles) => {
          const allowedFormats = ['pdf', 'csv', 'doc', 'docx', 'xls', 'xlsx'];
          const invalidFiles = [];
    
          const filteredFiles = acceptedFiles.filter(file => {
            const fileExtension = file.name.split('.').pop();
           
            if (!allowedFormats.includes(fileExtension)) {
              invalidFiles.push(file.name);
              return false; // Filter out invalid files
            }else
              return true; // Keep valid files
          });
    
          if (invalidFiles.length > 0) {
            toast.error('The following files have invalid formats: ' + invalidFiles.join(', '));
          } 
            setTaskFiles(prevFiles => [...prevFiles, ...filteredFiles]);

          
          acceptedFiles=[]

        },
      });
   
      // const getFileExtension = (fileName) => {
      //   return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
      // };

  const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
  

  const handleSubmitTask = async(e)=>{
        e.preventDefault();
        const formdataTask = new FormData(e.currentTarget);
       
        const service=serviceData._id;
        // const taskSubject = formdataTask.get("taskSubject");
        // const assignedTo = formdataTask.get('assignedTo');
        // const priority =formdataTask.get('priority');
      

        if(showIndiGroup==="indi"){
          const asgnId=formdataTask.get('assignedTo');
          if(!asgnId || asgnId==="" || asgnId.length<1){
            toast.error("Select the NOC!");
            return false
          }
          formdataTask.append('groupFlag', "N");
        }else if(showIndiGroup==="group"){
          const groupId=formdataTask.get('assignedToGroup');
          if(!groupId || groupId==="" || groupId.length<1){
            toast.error("Select the Group!");
            return false
          }

          formdataTask.append('groupFlag', "Y");

        }else
        {
          toast.error("Some error occured");
          return false;
        }

        formdataTask.append('service', service);
        formdataTask.append('assignedFrom', employee._id);
        //formdataTask.append('createdBy', employee._id);
       
        taskFiles.forEach((file) => {
          formdataTask.append('attachments', file);
        });

        editTask===true ?  formdataTask.append('updatedBy', employee._id):
          formdataTask.append('createdBy', employee._id);

    
        try{
          let response = await apiConnector({ 
            method:  editTask===true?"PUT":"POST",
            url: editTask===true?`${url}/api/task/${taskData._id}`:`${url}/api/task`,
            bodyData:formdataTask,
            headers: headersForFile,
          });

          if (response.status === 201 || response.status === 200) {
            setRefresh(!refresh);
            setTaskFiles([]);
            toast.success( "Task Created/updated successfully!");
            closeRefTask.current.click();
            resetFormTask.current.reset(); 
            setEditTask(false);
            
    }else{
            toast.error("Not Added, Some Error");
    }


        }catch(error){
          toast.error(error?.response?.data?.message);
          console.error("Error in Submitting Task Form",error);
        }

  }


  const handleFilesAccepted = (files) => {
    // Handle file acceptance for Form 2
    setThreadFiles([...threadFiles, ...files]);
  };

  const handleSubmitThread = async (e) => {
        e.preventDefault();
        // console.log(threadFiles);

        const { threadSubject, files , threadMessage} = formData;
        // if (!threadSubject || threadSubject.trim() === '') {
        //   toast.error("Subject Required!");
        //   setLoading(false);
        //   return false;
        // }
        if (!threadMessage || threadMessage.trim() === '') {
          toast.error("Message Required!");
          setLoading(false);
          return false;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('threadSubject', threadSubject);
        formDataToSend.append('task', taskData._id);
        formDataToSend.append('createdBy',employee._id);
        formDataToSend.append('message',threadMessage);
        threadFiles.forEach((file) => {
          formDataToSend.append('attached', file);
        });
        try {
                let response = await apiConnector({ 
                        method: "POST",
                        url: `${url}/api/thread`,
                        bodyData: formDataToSend,
                        headers: headersForFile,
                });
                if (response.status === 201) {
                        setRefresh(!refresh);
                        toast.success("Comment Added successfully");
                         closeRefThread.current.click();
                        reqForRef.current.reset(); 
                        setFormData({  threadSubject: '',
                                 threadMessage:'',  })
                        setThreadFiles([]);
                }else{
                        toast.error("Not Added, Some Error");
                }
        } catch (error) {
          console.error('Error uploading files:', error);
          toast.error("Not Added, Some Error");
        }
      };


      
// console.log("taskData  ",taskData);

const handleTaskStatuChange = async () => {

       const sure =  window.confirm("Confirm to update the Task Status as In Progress? ")
       if(!sure) return false;
        try {
                let response = await apiConnector({ 
                        method: "PUT",
                        url: `${url}/api/taskStatusInProgress/${taskData._id}`,
                        bodyData: {
                          updatedBy:employee._id
                        },
                        headers: headers,
                });
                if (response.status === 200) {
                        setRefresh(!refresh);
                        toast.success("Task Status changed successfully");

                }else{
                        toast.error("Some error occured!");
                }
        } catch (error) {
          console.error('Error uploading files:', error);
          toast.error("Not Added, Some Error");
        }
      };


      const handleReopenTask = async () => {

        const sure =  window.confirm("Confirm to reopen the Task? ")
        if(!sure) return false;
         try {
                 let response = await apiConnector({ 
                         method: "PUT",
                         url: `${url}/api/task/reopen/${taskData._id}`,
                         bodyData: {
                           updatedBy:employee._id
                         },
                         headers: headers,
                 });
                 if (response.status === 200) {
                         setRefresh(!refresh);
                         toast.success("Task Status reopened successfully");
 
                 }else{
                         toast.error("Some error occured!");
                 }
         } catch (error) {
           console.error('Error uploading files:', error);
           toast.error(`Error: ${error?.response?.data?.message}`);
         }
       };
 

      
const deleteTaskAttchment = async (fileToDelete) => {

        const sure =  window.confirm(`Confirm to delete ${fileToDelete} attchment from Task? `)
        if(!sure) return false;
         try {
                 let response = await apiConnector({ 
                         method: "put",
                         url: `${url}/api/taskDeleteAttachment/${taskData._id}`,
                         bodyData: {
                          fileName:fileToDelete,
                           updatedBy:employee._id
                         },
                         headers: headers,
                 });
                 if (response.status === 200) {
                         setRefresh(!refresh);
                         toast.success("Attachment deleted successfully");
 
                 }else{
                         toast.error("Error occured");
                 }
         } catch (error) {
           console.error('Error uploading files:', error);
           toast.error("Error: "+ error?.response?.data?.message);
         }
       };
 

       

const deleteThreadkAttchment = async (fileToDelete,threadId) => {

        const sure =  window.confirm(`Confirm to delete ${fileToDelete} attchment from Thread? `)
        if(!sure) return false;
         try {
                 let response = await apiConnector({ 
                         method: "put",
                         url: `${url}/api/threadDeleteAttachment/${threadId}`,
                         bodyData: {
                          taskId:taskData._id,
                          fileName:fileToDelete,
                           updatedBy:employee._id
                         },
                         headers: headers,
                 });
                 if (response.status === 200) {
                         setRefresh(!refresh);
                         toast.success("Attachment deleted successfully");
 
                 }else{
                         toast.error("Error occured");
                 }
         } catch (error) {
           console.error('Error uploading files:', error);
           toast.error("Error: "+ error?.response?.data?.message);
         }
       };


  
const handleRemoveFile = (indexToRemove) => {
        setTaskFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
      };
    
const handleRemoveFileThread = (indexToRemove) => {
      setThreadFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
      };

      

  //fetching account details by ID
useEffect(() => {
        const params = {
          method: "GET",
          url: `${url}/api/services/${id}`,
          headers: headers,
        }; 
      apiConnector(params)
        .then((response) => {
            setServiceData(response.data);
            setAccountData(response.data.accountId);
        })
        .catch((error) => {
          console.error("Error fetching daily progress reports:", error);
          // setLoading(false);
        });

  }, [refresh]);


useEffect(()=>{

        const taskParams = {
                method: "GET",
                url: `${url}/api/taskByService/${serviceData?._id}`,
                headers: headers,
              }; 

        if(serviceData?._id){
            apiConnector(taskParams)
              .then((response) => {
                setTaskData(response.data);
                setThreadData(response.data?.threads)
                  
              })
              .catch((error) => {
                console.error("Error fetching daily progress reports:", error);
                // setLoading(false);
              });
        }

},[serviceData]);



useEffect(()=>{

  const paramsNoc = {
          method: "GET",
          url:  serviceData?.product==="VOICE"? `${url}/api/employeeActiveNOC_VOICE` :
          serviceData?.product==="SMS"?  `${url}/api/employeeActiveNOC_SMS`:"" ,
          headers: headers,
        }; 

  if(paramsNoc?.url!==""){
      apiConnector(paramsNoc)
        .then((response) => {
          setNOCData(response.data);
            
        })
        .catch((error) => {
          console.error("Error fetching daily progress reports:", error);
          // setLoading(false);
        });
  }



  const paramsGroupNoc = {
    method: "GET",
    url:  serviceData?.product==="VOICE"? `${url}/api/empgroupActive_VOICE` :
    serviceData?.product==="SMS"?  `${url}/api/empgroupActive_SMS`:"" ,
    headers: headers,
  }; 

if(paramsGroupNoc?.url!==""){
apiConnector(paramsGroupNoc)
  .then((response) => {
    setNOCGroupData(response.data);
      
  })
  .catch((error) => {
    console.error("Error fetching daily progress reports:", error);
    // setLoading(false);
  });
}

},[serviceData]);


// console.log(nocGroupData);


const formatDate= (date)=>{
        return moment(date).format('D MMM YYYY h:mm A');
  }


  const [showModal, setShowModal] = useState(false);

  const handleTestingStatusChange = async (status) => {
    // Handle accept action
    
     const sure =  window.confirm(`Confirm to update the Task Status as ${status}? `)
     if(!sure) return false;
      try {
              let response = await apiConnector({ 
                      method: "PUT",
                      url: `${url}/api/taskStatus/${taskData._id}`,
                      bodyData: {
                        statusToChange:status,
                        updatedBy:employee._id
                      },
                      headers: headers,
              });
              if (response.status === 200) {
                      setRefresh(!refresh);
                      toast.success("Task Status changed successfully");

              }else{
                      toast.error("Some error occured!");
              }
      } catch (error) {
        console.error('Error uploading files:', error);
        toast.error("Not Added, Some Error");
      }
    setShowModal(false);
  };

  const handleCancel = () => {
    // Handle cancel action
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const [selectedEmpId,setSelectedEmpId]=useState(null);

  const handleCloseDialog = () => {
    setSelectedEmpId (null);
  }

  const handleEmployeeClick =(empid)=>{
    setSelectedEmpId(empid);
}

  return (
    <Sidebar>
      <div className="container-fluid bdy overflow-x-auto">
      <EmployeeDetailsDialog empid={selectedEmpId} onClose={handleCloseDialog} />
        <div className="timeline">
          <div className="contner left-contner mb-2  min-w-[500px] ">
            <FontAwesomeIcon
              icon={faCircle}
              className="h-5 text-red-500   img"
            />
            {/* <span className='img  text-black text-bold border-2 shadow-lg bg-white text-center'>23 Apr 2024</span> */}
            <div className="text-box  text-black shadow-lg border border-red border-solid border-10 ">
              <h5 className="text-md text-black font-bold  text-[#1d3050]">
                Account Created on {formatDate(accountData?.createdAt)}(
                {accountData?.accountType})
              </h5>
              <small>
                Last Updated at {formatDate(accountData?.updatedAt)}
              </small>
              <p> Account Name : {accountData?.name} ,   Company Name : {employee.role==='admin'? accountData?.companyName :  accountData?.ghostName} , 
              {employee.role==='admin' && (`Ghost Name : ${accountData?.ghostName}` )}  
               Email: { employee.role==='admin' ? accountData?.email : 'xxxxxxxxxxxx'}  ,  
              Phone : {employee.role==='admin'? accountData?.phone : 'xxxxxxxxxxxxx'} </p>
              <button
                onClick={() => {
                  navigate(`/account?id=${accountData?._id}`);
                }}
                className="btn btn-sm bg-primary text-white"
              >
                View Account
              </button>
              <span className="left-contner-arrow"></span>
            </div>
          </div>
          <div className=" contner right-contner mb-2 min-w-[500px]  ">
            <FontAwesomeIcon
              icon={faCircle}
              className="h-5 text-red-500   img"
            />
            <div className="text-box  text-black shadow-lg border border-red border-solid border-10 ">
              <h2 className="text-md text-black font-bold  text-[#1d3050]">
                Route Created on {formatDate(serviceData?.createdAt)}(
                {serviceData?.product})
              </h2>
              <small className="font-bold">
                Created by {serviceData?.createdBy?.name} [
                {serviceData?.createdBy?.role},{serviceData?.createdBy?.email},
                {serviceData?.createdBy?.phone}
                {serviceData?.createdBy?.productCategory}]
              </small>
              <br />
              <small className="">
                Last Updated at {formatDate(serviceData?.updatedAt)}
              </small>
              <div className="grid grid-cols-2 gap-0 mt-2">
                <div className="border p-2">
                  
                  <p>   <span className="font-bold">Destination: </span> {serviceData?.destinationName?.name}</p>
                </div>
                <div className="border p-2">
                 
                  <p> <span className="font-bold">Currency: </span> {serviceData?.currency?.name}</p>
                </div>
                <div className="border p-2">
                  
                  <p> <span className="font-bold">Pricing: </span> { 
                  (employee.role === 'admin' || serviceData?.ghostVisible !== true) ? serviceData?.pricing : serviceData?.ghostPricing
                  }</p>
                </div>
                <div className="border p-2">
                 
                  <p>  <span className="font-bold">Quality Category: </span> {serviceData?.qualityCategory?.name}</p>
                </div>

                <div className="border p-2">
                 
                  <p>  <span className="font-bold">Testing Status: </span>
                   { utils.resolveStatus(serviceData?.testingStatus)}
                   </p>
                </div>
                {serviceData?.ghostVisible === true && employee.role==='admin' && (
                  <div className="border p-2">
                  
                    <p>   <span className="font-bold">Ghost Price:</span> {serviceData?.ghostPricing}</p>
                  </div>
                )}
              </div>
              <div className="grid grid-cols-1 gap-4 mt-4">
            { taskData?.taskStatus!=='DONE' ? ( taskData  && Object.keys(taskData).length !== 0 ? (
                  <div className="border flex   p-2">
                    <p className="font-bold  ">Task already created : </p>
                    <button
                      data-toggle="modal"
                      data-target="#addTaskModal"
                      className="btn-sm text-white bg-primary ml-20"
                      onClick={()=> setEditTask(true) }
                    >
                      Edit Task
                    </button>
                  </div>
                ) : (
                  <div className="border p-2">
                    <p
                      className="font-bold flex w-full"
                      data-toggle="modal"
                      data-target="#addTaskModal"
                    >
                      Create Task for this Route :
                      <button className="btn-sm text-white ml-20 bg-primary">
                        Create Task
                      </button>
                    </p>
                  </div>
                )  ) :   <div className="border flex   p-2">
                <p className="font-bold  ">Re-open Task : </p>
                <button
                  // data-toggle="modal"
                  // data-target="#addTaskModal"
                  className="btn-sm text-white bg-primary ml-20"
                  onClick={()=> handleReopenTask() }
                >
                  Re-open Task
                </button>
              </div>} 



              </div>

              <span className="right-contner-arrow"></span>
            </div>
          </div>

          {/* <div className="contner right-contner mb-2">
      <Box sx={{ overflowX: 'auto', padding: '1rem' }}>
        <IconButton>
          <FontAwesomeIcon icon={faCircle} className="h-5 text-red-500 img" />
        </IconButton>
        <Paper elevation={3} sx={{ padding: '1rem', border: '1px solid red' }}>
          <Typography variant="h6" color="#1d3050" fontWeight="bold">
            Route Created on {formatDate(serviceData?.createdAt)} ({serviceData?.product})
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            Created by {serviceData?.createdBy?.name} [ {serviceData?.createdBy?.role},
            {serviceData?.createdBy?.email}, {serviceData?.createdBy?.phone}
            {serviceData?.createdBy?.productCategory}]
          </Typography>
          <br />
          <Typography variant="body2">
            Last Updated at {formatDate(serviceData?.updatedAt)}
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ border: '1px solid', padding: '1rem' }}>
                <Typography variant="body2">
                  <span className="font-bold">Destination: </span> {serviceData?.destinationName?.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ border: '1px solid', padding: '1rem' }}>
                <Typography variant="body2">
                  <span className="font-bold">Currency: </span> {serviceData?.currency?.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ border: '1px solid', padding: '1rem' }}>
                <Typography variant="body2">
                  <span className="font-bold">Pricing: </span> 
                  {employee.role === 'admin' || serviceData?.ghostVisible === false ? serviceData?.pricing : serviceData?.ghostPricing}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ border: '1px solid', padding: '1rem' }}>
                <Typography variant="body2">
                  <span className="font-bold">Quality Category: </span> {serviceData?.qualityCategory?.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ border: '1px solid', padding: '1rem' }}>
                <Typography variant="body2">
                  <span className="font-bold">Testing Status: </span> {utils.resolveStatus(serviceData?.testingStatus)}
                </Typography>
              </Box>
            </Grid>
            {serviceData?.ghostVisible === true && employee.role === 'admin' && (
              <Grid item xs={12} sm={6}>
                <Box sx={{ border: '1px solid', padding: '1rem' }}>
                  <Typography variant="body2">
                    <span className="font-bold">Ghost Price:</span> {serviceData?.ghostPricing}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
          <Box sx={{ marginTop: '2rem' }}>
            {taskData?.taskStatus !== 'DONE' ? (
              taskData && Object.keys(taskData).length !== 0 ? (
                <Box sx={{ border: '1px solid', padding: '1rem', display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2" fontWeight="bold">
                    Task already created:
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ marginLeft: 'auto' }}
                    onClick={() => setEditTask(true)}
                  >
                    Edit Task
                  </Button>
                </Box>
              ) : (
                <Box sx={{ border: '1px solid', padding: '1rem' }}>
                  <Typography variant="body2" fontWeight="bold" display="flex" alignItems="center">
                    Create Task for this Route:
                    <Button variant="contained" color="primary" size="small" sx={{ marginLeft: 'auto' }}>
                      Create Task
                    </Button>
                  </Typography>
                </Box>
              )
            ) : (
              <Box sx={{ border: '1px solid', padding: '1rem', display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" fontWeight="bold">
                  Re-open Task:
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ marginLeft: 'auto' }}
                  onClick={() => handleReopenTask()}
                >
                  Re-open Task
                </Button>
              </Box>
            )}
          </Box>
          <span className="right-contner-arrow"></span>
        </Paper>
      </Box>
    </div> */}

          {taskData && Object.keys(taskData).length !== 0 && (
            <>
              <div className="contner left-contner  mb-2  min-w-[500px] ">
                <FontAwesomeIcon
                  icon={faCircle}
                  className="h-5 text-red-500   img"
                />
                <div className="text-box  text-black shadow-lg border border-red border-solid border-10 ">
                  <h5 className="text-md text-black font-bold  text-[#1d3050]">
                    
                    Task Created on {formatDate(taskData?.createdAt)}
                  </h5>
                  <small className="font-bold">
                    Created by {taskData?.createdBy?.name} [
                    {taskData?.createdBy?.role},{taskData?.createdBy?.email},
                    {taskData?.createdBy?.phone} ]
                  </small>
                  <br />
                  <small className="font-bold">
                    Assigned by {taskData?.assignedFrom?.name} [
                    {taskData?.assignedFrom?.role},
                    {taskData?.assignedFrom?.email},
                    {taskData?.assignedFrom?.phone} ]
                  </small>
                  <br />
                  <small className="">
                    Last Updated at {formatDate(taskData?.updatedAt)}
                  </small>
                  <div className="grid grid-cols-2 gap-0 mt-2">
                    <div className="border  col-span-2  p-1">
                      <p className="font-bold ">
                        Subject: {taskData?.taskSubject}
                      </p>
                    </div>
                    <div className="border p-1">
                      <p className="font-bold">
                        Priority: { utils.resolvePriority(taskData?.priority)}
                      </p>
                    </div>
                    <div className="border p-1">
                      <p className="font-bold">Status</p>
                      <p>{taskData?.taskStatus}</p>
                    </div>
                { taskData?.attachments.length !==0 ?   <div className="border col-span-2  p-1">
                      <p className="font-bold">Attachments:</p>
                      <p>
                        {taskData?.attachments?.map((file, index) => (
                          <>
                            
                            <a
                              key={index}
                              href={`${url}/file/task/${file}`}
                              target="_blank" rel="noreferrer"
                            >
                              {file}
                            </a> <a className=" ml-3 btn btn-sm bg-red-600 text-white" onClick={()=>{deleteTaskAttchment(file)}}> Delete</a>
                            <br />
                          </>
                        ))}
                      </p>
                    </div> : null}
                    { taskData?.assignedTo && <div className="border col-span-2  p-1">
                      <p className="font-bold">Assigned To:</p>
                       
                        <button onClick={()=> handleEmployeeClick(`${taskData.assignedTo._id}`)} > {taskData?.assignedTo?.name} </button> 
                          [{taskData?.assignedTo?.role}
                      , {taskData?.assignedTo?.email},
                      {taskData?.assignedTo?.phone} ]
                    </div>}
                    <div className="border col-span-2 p-1">
                      <p className="font-bold">Message:</p>
                      <p style={{ whiteSpace: 'pre-line' }} >{taskData?.comments}</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-4 mt-4"></div>
                  <span className="left-contner-arrow"></span>
                </div>
              </div>

              {threadData &&
                threadData.map((item, index) => (
                  <div 
                    key={index}
                    className={`mb-2  contner ${
                      index % 2 === 0 ? "right-contner" : "left-contner"
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="h-5 text-red-500   img"
                    />
                    <div className="text-box  text-black shadow-lg border border-red border-solid border-10 ">
                      <h2 className="text-md text-black font-bold  text-[#1d3050]">
                        
                        Thread : {formatDate(item?.createdAt)}
                      </h2>
                      {/* <br/><small className="">Last Updated at { formatDate(item?.threadSubject)}</small> */}
                      <div className="grid grid-cols-2 gap-0 mt-1">
                      {item?.threadSubject &&  <div className="border  col-span-2  p-1">
                         
                          <p> Subject :  {item?.threadSubject}</p>
                        </div>}
                        <div className="border col-span-2 p-1">
                          <p className="font-bold">Message: </p>
                          <p style={{ whiteSpace: 'pre-line' }}>{item?.message}</p>
                        </div>

                        { item?.attachments.length !==0 ?       <div className="border col-span-2  p-1">
                         
                          <p>
                          <span className="font-bold"> Attachments: </span>
                            {item?.attachments?.map((file, index) => (
                             <p> <a
                                key={index}
                                href={`${url}/file/thread/${file}`}
                                target="_blank" rel="noreferrer"
                              >
                                {file}
                              </a>   <a className=" ml-3 btn btn-sm bg-red-600 text-white" onClick={()=>{deleteThreadkAttchment(file,item._id)}}> Delete</a> </p>
                            ))}
                          </p>
                        </div> : null  }
                        <div className="border col-span-2  p-1">
                           
                          <small>
                          <span className="font-bold"> From: </span>
                            {item?.createdBy !== null ? (
                              <>
                                
                                {item?.createdBy?.name} [
                                {item?.createdBy?.role},
                                {item?.createdBy?.email},
                                {item?.createdBy?.phone}]
                              </>
                            ) : (
                              "System Genereated"
                            )}
                          </small>
                        </div>
                      </div>

                      {index === threadData.length - 1   && (
                        <div className="grid grid-cols-2 gap-4 mt-4">
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#addNewReqModa"
                            className="btn btn-primary btn-sm text-white"
                          >
                            Add comment or Reply
                          </button>

                          {
                              
                              taskData?.taskStatus!=='DONE' && ( taskData.taskStatus==="PENDING"  ? (<button className="btn btn-info btn-sm text-white" onClick={handleTaskStatuChange} >
                                 Mark Task Status in Progress
                               </button>) : (  <>  
                                <button className="btn btn-info btn-sm text-white" onClick={handleOpenModal}>
                                      Mark Route as Failed or Pass
                               </button>

                                {showModal && (
                                          <div className="col-span-2 border">
                                          <p>Choose the status : </p>
                                          <button className="btn btn-sm bg-primary text-white  m-2" onClick={()=>handleTestingStatusChange("PASSED")}>Mark Test Pass</button>
                                          <button className="btn btn-sm bg-info text-white  m-2" onClick={()=>handleTestingStatusChange("FAILED")}>Mark Test Failed</button>
                                          <button className="btn btn-sm bg-red-600 text-white  m-2" onClick={handleCancel}>Cancel</button>
                                        </div>
                                        )}
                               </>  )
                      )
                          }
                        
                        </div>
                      )}

                      <span
                        className={` ${
                          index % 2 === 0
                            ? "right-contner-arrow "
                            : "left-contner-arrow "
                        }`}
                      ></span>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>

        {/* FORM FOR THREAD CREATION */}
        <div
          className="modal  fade "
          id="addNewReqModa"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addNewReqModaTitle"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered " role="document">
            <form onSubmit={handleSubmitThread} ref={reqForRef}>
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="font-semibold text-xl text-gray-600">
                       Add Comment or Reply
                  </h2>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref={closeRefThread}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row    p-1  flex items-center justify-center ">
                    <div className="container  ">
                      <div>
                        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-2 mb-6">
                          <div className="grid gap-4 gap-y-2 text-black text-sm grid-cols-2 ">
                            <div className="">
                              <label htmlFor="subject">Subject:</label>
                              <input
                                type="text"
                                id="threadSubject"
                                className=" md:col-span-2 form-control"
                                name="threadSubject"
                                value={formData.threadSubject}
                                onChange={handleInputChange}
                                
                              />
                            </div>

                            <div className="md:col-span-6">
                              <label htmlFor="qualityCategory">
                                Attachments :
                              </label>

                             < DropzoneCompo onFilesAccepted={handleFilesAccepted}/>
                             <ol  className="list-decimal list-inside">
                                {   threadFiles &&
                                  threadFiles.map((file, index) => (
                                   
                                    <li className="" key={file.path}> 
                                      {file.path}  
                                      <span
                                        className="btn btn-sm ml-3 text-white bg-red-600"
                                        onClick={() => handleRemoveFileThread(index)}
                                      >
                                        Remove
                                      </span><br/>
                                    </li> 
                                    
                                  ))}
                              </ol>
                            </div>

                            <div className=" md:col-span-6">
                              <label htmlFor="message">Message:</label>
                              <textarea
                                type="text"
                                id="message"
                                name="threadMessage"
                                className=" md:col-span-2 form-control"
                                value={formData.threadMessage}
                                onChange={handleInputChange}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer md:col-span-7 flex justify-between  ">
                  <button
                    type="button"
                    className=" bg-red-600 mr-auto text-md flex justify-content  text-white rounded px-4 py-1 font-medium"
                    data-dismiss="modal"
                  >
                    Close
                  </button>

                  <button
                    className=" text-md bg-blue-400 flex justify-content  text-white rounded px-4 py-1 font-medium"
                    type="submit"
                  >
                    {loading ? <Loader /> : "SUBMIT"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* FORM FOR TASK CREATION */}
        <div
          className="modal  fade "
          id="addTaskModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addTaskModalTitle"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered " role="document">
            <form onSubmit={handleSubmitTask} ref={resetFormTask}>
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="font-semibold text-xl text-gray-600">
                  {editTask===true ? "Edit":"Create" }  Create Task
                  </h2>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref={closeRefTask}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row    p-1  flex items-center justify-center ">
                    <div className="container  ">
                      <div>
                        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-2 mb-6">
                          <div className=" gap-4 gap-y-2 text-black text-sm  ">
                            
                            <div className="row m-2">
                              <label htmlFor="subject"> Subject: </label>
                              <input
                                type="text"
                                id="taskSubject"
                                className=" w-full form-control"
                                name="taskSubject"
                                defaultValue={editTask===true ? taskData.taskSubject:""}
                              />
                            </div>
                          
                            <div className="row m-2" >
                              <label htmlFor="assignedTo">Assigned To:</label>
                                
                                <div className="col-sm-12 p-1"> 
                                   <input type="radio" value="indi" name="giRadio"  checked={showIndiGroup === 'indi'}  onChange={()=>setShowIndiGroup("indi")} className=" m-2"/> Individual
                                    <input type="radio" value="group" name="giRadio"  checked={showIndiGroup === 'group'}  onChange={()=>setShowIndiGroup("group")} className="m-2"/> Group
                                </div>
                               
                         {  showIndiGroup ==="group" &&    <select
                                className="form-control"
                                name="assignedToGroup"
                              >
                                <option value="">Select Group</option>
                                {nocGroupData &&
                                  nocGroupData.map((item, index) => (
                                    <option key={index} value={item._id} 
                                    //  selected={   (editTask===true && taskData.assignedTo._id ===item._id) ? true:false  } 
                                      >
                                      {item.name}[{item?.empList?.length} Member - {item.productCategory}]
                                    </option>
                                  ))}
                              </select>}

                            { showIndiGroup==="indi" && <select
                                className="form-control"
                                name="assignedTo"
                              >
                                <option value="">Select NOC</option>
                                {nocData &&
                                  nocData.map((item, index) => (
                                    <option key={index} value={item._id} 
                                     selected={   (editTask===true && taskData.assignedTo?._id ===item._id) ? true:false  } 
                                      >
                                      {item.name}[{item.email} -
                                      {item.productCategory}]
                                    </option>
                                  ))}
                              </select>}

                            </div>

                                    

                            <div className="row m-2">
                              <label htmlFor="assignedTo">Priority:</label>
                              <select className="form-control" name="priority" defaultValue={   editTask===true ? taskData.priority : 3}> 
                              <option selected={   (editTask===true && taskData.priority ===3) ? true:false  }  value="3">Low</option>
                                <option selected={   (editTask===true && taskData.priority ===2) ? true:false  } value="2">Medium</option>
                                <option selected={   (editTask===true && taskData.priority ===1) ? true:false  } value="1">High</option>
                              </select>
                            </div>

                            <div className="row m-2">
                              <label htmlFor="qualityCategory">
                                Attachments :
                              </label>

                              <div
                                {...getRootProps({ className: "dropzone" })}
                                className="dropzone border-2 border-dashed border-gray-400 rounded-md p-4 text-center"
                              >
                                <input
                                  {...getInputProps({
                                    accept: ".pdf,.csv,.doc,.docx,.xls,.xlsx",
                                  })}
                                />
                                <p className="text-gray-600">
                                  Drag 'n' drop some files here, or click to
                                  select files
                                </p>
                              </div>
                              <ul>
                                {   taskFiles &&
                                  taskFiles.map((file, index) => (
                                    <li key={file.path}>
                                      {file.path} -{/* {file.size} Mb */}
                                      <span
                                        className="btn btn-sm ml-3 text-white bg-red-600"
                                        onClick={() => handleRemoveFile(index)}
                                      >
                                        Remove
                                      </span>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                            
                            <div className="row m-2">
                              <label htmlFor="comments">Message:</label>
                              <textarea
                                type="text"
                                id="comments"
                                name="comments"
                                className=" form-control"
                                defaultValue={ editTask===true ? taskData.comments: "" }
                            
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer md:col-span-7 flex justify-between  ">
                  <button
                    type="button"
                    className=" bg-red-600 mr-auto text-md flex justify-content  text-white rounded px-4 py-1 font-medium"
                    data-dismiss="modal"
                  >
                    Close
                  </button>

                  <button
                    className=" text-md bg-blue-400 flex justify-content  text-white rounded px-4 py-1 font-medium"
                    type="submit"
                  >
                    {loading ? <Loader /> : "SUBMIT"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};


export default TaskTimeline;
