
import React from "react";
import Sidebar from "../Components/Sidebar";

import MappingNotificationCompo from "../Components/MappingNotificationCompo";

const Mapper = () => {
  

  return (
    <Sidebar>

        <div className="container-fluid">
          <MappingNotificationCompo/>
        </div>

  </Sidebar>
  );
};

export default Mapper;