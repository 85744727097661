import React, { useState, useEffect ,useRef} from "react";
import Sidebar from "../Components/Sidebar";
import { apiConnector } from "../../services/apiConnector";
import Loader from "../../components/loader/loader";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {url} from '../../services/api'
import AccountsComp from "../Components/AccountsComp";


export default function Accounts() {

  const employee = useSelector((state) => state.employee);
  const headers = {
    Authorization: `Bearer ${employee.token}`,
  };
  const closeRef = useRef();
  const openAccountModalRef=useRef();
  const resetFormRef = useRef();

  const [loading, setLoading] = useState(false);
  const [loadCompoent,SetLoadCompoent]=useState(false);
  const [country,setCountry]=useState([]);
  const [currency,setCurrency]=useState([]);
  const [paymentCycle,setPaymentCycle]=useState([]);
  const [timeZone,setTimeZone]=useState([]);

  const[amList,setAmList]=useState([]);
  const[changeInCategory,setChangeInCategory]=useState("");

  const [accountEditData,setAccountEditData]=useState(null);

  useEffect(()=>{
    const paramsEmpAM = {
      method: "GET",
      url: (changeInCategory==='VOICE') ? `${url}/api/employeeActiveAM_VOICE` : 
              (changeInCategory==='SMS')? `${url}/api/employeeActiveAM_SMS` :"" ,
      headers: headers,
    };
    if(paramsEmpAM.url!==""){
        apiConnector(paramsEmpAM)
        .then((response) => {
          setAmList(response.data);   
        })
        .catch((error) => {
          console.error("Error fetching List : ", error);
        });
  }
  },[changeInCategory]);


  useEffect(() => {

    const paramsCountry = {
      method: "GET",
      url: `${url}/api/countryActive`,
      headers: headers,
    };

    const paramsPaymentCycle = {
      method: "GET",
      url: `${url}/api/paymentCycleActive`,
      headers: headers,
    };
    const paramsTimeZone = {
      method: "GET",
      url: `${url}/api/timeZoneActive`,
      headers: headers,
    };

    const paramsCurrency = {
      method: "GET",
      url: `${url}/api/currencyActive`,
      headers: headers,
    };



  apiConnector(paramsCountry)
    .then((response) => {
      setCountry(response.data);   
    })
    .catch((error) => {
      console.error("Error fetching List : ", error);
    });

    apiConnector(paramsPaymentCycle)
    .then((response) => {
      setPaymentCycle(response.data);   
    })
    .catch((error) => {
      console.error("Error fetching List : ", error);
    });

    apiConnector(paramsTimeZone)
    .then((response) => {
      setTimeZone(response.data);   
    })
    .catch((error) => {
      console.error("Error fetching List : ", error);
    });

    apiConnector(paramsCurrency)
    .then((response) => {
      setCurrency(response.data);   
    })
    .catch((error) => {
      console.error("Error fetching List : ", error);
    });



}, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formdata = new FormData(e.currentTarget);
    const _name = formdata.get('name');
    let _companyName=formdata.get('companyName');
    const _email = formdata.get('email');
    const _phone = formdata.get('phone');
    const _address = formdata.get('address');
    const _country = formdata.get('country');
    const _currency = formdata.get('currency');
    const _paymentCycle = formdata.get('paymentCycle');
    const _timeZone = formdata.get('timeZone');
    const _ghostName = formdata.get("ghostName");
    const _billingEmail = formdata.get("billingEmail");
    const _salesEmail = formdata.get("salesEmail");
    const _skypeId=formdata.get("skypeId");
    const _remarks =formdata.get("remarks");
    let _assignedTo=null;
    let _accountType=null;

    if( (employee.role==='admin' || employee.role==='Account Manager' ) &&  (employee?.category==='VOICE' || employee?.category==='SMS') ){
         _assignedTo=employee._id;
         _accountType=employee.category
         console.log("enered here ", _accountType)
    }else if (employee.role==='admin'  &&  ( !employee?.category || employee.category==="null"))  {
      _accountType=formdata.get("accountType");
      _assignedTo=formdata.get("assignedTo");
    }


      if(employee.role!=='admin'){
        _companyName = _ghostName;
      }

        // Validate 'name' field
    if (!_name || _name.trim() === '') {
      toast.error("Account Name Required!");
      setLoading(false);
      return false;
    }

    // Validate 'companyName' field
    if (!_companyName || _companyName.trim() === '') {
      toast.error("Company Name Required!"); 
      setLoading(false);
      return false;
    }

     // Validate 'ghostName' field
     if (!_ghostName || _ghostName.trim() === '') {
      if(employee.role==='admin')
        toast.error("Ghost Name Required!");
      else
         toast.error('Company Name Required!');
      setLoading(false);
      return false;
    }

    // Validate 'email' field
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!_email || !emailPattern.test(_email)) {
      toast.error("Valid Email Required!");
      setLoading(false);
      return false;
    }

    // // Validate 'phone' field
    // const phonePattern = /^\+?[0-9]{8,}$/;
    // if (!_phone || !phonePattern.test(_phone)) {
    //   toast.error("Valid Phone Number Required!");
    //   setLoading(false);
    //   return false;
    // }

    // Validate 'address' field
    if (!_address || _address.trim() === '') {
      toast.error("Address Required!");
      setLoading(false);
      return false;
    }

    // Validate 'country' field
    if (!_country || _country.trim() === '') {
      toast.error("Country Required!");
      setLoading(false);
      return false;
    }

    // Validate 'currency' field
    if (!_currency || _currency.trim() === '') {
      toast.error("Currency Required!");
      setLoading(false);
      return false;
    }

    // Validate 'paymentCycle' field
    if (!_paymentCycle || _paymentCycle.trim() === '') {
      toast.error("Payment Cycle Required!");
      setLoading(false);
      return false;
    }

    // Validate 'timeZone' field
    if (!_timeZone || _timeZone.trim() === '') {
      toast.error("Time Zone Required!");
      setLoading(false);
      return false;
    }

   

    // Validate 'billingEmail' field
    if (!_billingEmail || !emailPattern.test(_billingEmail)) {
      toast.error("Valid Billing Email Required!");
      setLoading(false);
      return false;
    }

    // Validate 'salesEmail' field
    // if (!_salesEmail || !emailPattern.test(_salesEmail)) {
    //   toast.error("Valid Sales Email Required!");
    //   setLoading(false);
    //   return false;
    // }

    // Validate 'skypeId' field
    if (!_skypeId || _skypeId.trim() === '') {
      toast.error("Skype ID Required!");
      setLoading(false);
      return false;
    }

    
    if (accountEditData===null  && (!_accountType || _accountType.trim() === '') ){
      toast.error("Account Type Required!" + _accountType);
      setLoading(false);
      return false;
    }

    if (accountEditData===null && (!_assignedTo || _assignedTo.trim() === '')) {
      toast.error("AM assgination Required!");
      setLoading(false);
      return false;
    }



    try {
      let response = await apiConnector({
        method:  accountEditData!==null && accountEditData?._id ? "PUT" :  "POST",
        url:   accountEditData!==null && accountEditData?._id ?  `${url}/api/account/${accountEditData?._id}`  :  `${url}/api/account`,
        bodyData: {
          name:_name,
          companyName:_companyName,
          email:_email,
          phone:_phone,
          address:_address,
          country:_country,
          currency:_currency,
          paymentCycle:_paymentCycle,
          timeZone:_timeZone,
          ghostName:_ghostName,
          billingEmail:_billingEmail,
          salesEmail:_salesEmail,
          skypeId:_skypeId,
          createdBy:employee._id,
          assignedTo:_assignedTo,
          accountType:_accountType,
          remarks:_remarks

        },
        headers: headers,
      });
      if (response?.status===201) {
        toast.success("Account Added Successfully.");
        closeRef.current.click();
        SetLoadCompoent(!loadCompoent);
      }else  if (response?.status===200) {
        toast.success("Account updated Successfully.");
        closeRef.current.click();
        SetLoadCompoent(!loadCompoent);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
    setLoading(false);
  };


  const handleResetForm= () =>{
    setAccountEditData(null);
    resetFormRef.current.reset();

  }


  return (
    <Sidebar>
      <div className="container-fluid mt-10">
        <div className="row">
          

          {/* <!-- Modal --> */}
          <div
            className="modal  fade "
            id="addNewAccModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="addNewAccModalTitle"
            aria-hidden="true"
            data-backdrop="static"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <form onSubmit={handleSubmit} ref={resetFormRef}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h2 className="font-semibold text-xl text-gray-600">
                      New Account Addition
                    </h2>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref={closeRef}
                      onClick={ handleResetForm}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row    p-1  flex items-center justify-center ">
                      <div className="container  ">
                        <div>
                          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-2 mb-6">
                            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                              <div className="lg:col-span-4">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                  <div className="md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      for="company_name"
                                    >
                                      Account Name
                                    </label>
                                    <input
                                      name="name"

                                      defaultValue={accountEditData && accountEditData?.name}
                                      type="text"
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                    />
                                  </div>

                                 {employee.role ==='admin' && <div className=" md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      for="destination"
                                    >
                                      Company Name
                                    </label>

                                    <input
                                      name="companyName"
                                      defaultValue={accountEditData && accountEditData?.companyName}
                                      type="text"
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                    />
                                  </div>}

                                  <div className=" md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      for="ghostName"
                                    >
                                      {employee.role==='admin'?'Ghost' : 'Company' } Name
                                    </label>
                                    <input
                                      name="ghostName"
                                      defaultValue={accountEditData && accountEditData?.ghostName}
                                      type="text"
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                    />
                                  </div>

                                  <div className=" md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      for="destination"
                                    >
                                      Email
                                    </label>

                                    <input
                                      name="email"
                                      defaultValue={accountEditData && accountEditData?.email}
                                      type="email"
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                    />
                                  </div>
                                  <div className=" md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      for="phone"
                                    >
                                      Phone
                                    </label>

                                    <input
                                      name="phone"
                                      defaultValue={accountEditData && accountEditData?.phone}
                                      type="text"
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                    />
                                  </div>
                                  <div className=" md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      for="address"
                                    >
                                      Address
                                    </label>

                                    <input
                                      name="address"
                                      defaultValue={accountEditData && accountEditData?.address}
                                      type="text"
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                    />
                                  </div>

                                  <div className="form-group  md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      htmlFor="Country"
                                    >
                                      Country:
                                    </label>
                                    <select
                                      name="country"
                                      className="form-control"
                                    >
                                      <option value="">Select Country</option>
                                      {country.map((ctry) => (
                                        <option selected={accountEditData && accountEditData?.country?._id===ctry._id} key={ctry._id} value={ctry._id}>
                                          {ctry.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="form-group  md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      htmlFor="currency"
                                    >
                                      Currency:
                                    </label>
                                    <select
                                      name="currency"
                                      className="form-control"
                                    >
                                      <option value="">Select Currecny</option>
                                      {currency.map((item) => (
                                        <option selected={accountEditData && accountEditData?.currency?._id===item._id} key={item._id} value={item._id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="form-group  md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      htmlFor="paymentCycle"
                                    >
                                      Payment Cycle:
                                    </label>
                                    <select
                                      name="paymentCycle"
                                      className="form-control"
                                    >
                                      <option value="">Payment Cycle</option>
                                      {paymentCycle.map((item) => (
                                        <option selected={accountEditData && accountEditData?.paymentCycle?._id===item._id} key={item._id} value={item._id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="form-group  md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      htmlFor="timeZone"
                                    >
                                      Time Zone:
                                    </label>
                                    <select
                                      name="timeZone"
                                      className="form-control"
                                    >
                                      <option value=""> Select TimeZone</option>
                                      {timeZone.map((item) => (
                                        <option selected={accountEditData && accountEditData?.timeZone?._id===item._id} key={item._id} value={item._id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  

                                  <div className=" md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      for="billingEmail"
                                    >
                                      Billing Email :
                                    </label>
                                    <input
                                      name="billingEmail"
                                      defaultValue={accountEditData && accountEditData?.billingEmail}
                                      type="text"
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                    />
                                  </div>

                                  <div className=" md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      for="salesEmail"
                                    >
                                      Sales Email :
                                    </label>
                                    <input
                                      name="salesEmail"
                                      defaultValue={accountEditData && accountEditData?.salesEmail}
                                      type="text"
                                      className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                    />
                                  </div>

                                 

                                  <div className=" md:col-span-3">
                                    <label
                                      className="text-black font-bold"
                                      for="skypeId"
                                    >
                                      Skype ID :
                                    </label>
                                    <input
                                      name="skypeId"
                                      defaultValue={accountEditData && accountEditData?.skypeId}
                                      placeholder="Enter Skype Id"
                                      type="text"
                                      className="h-10 border mt-1 text-black rounded px-4 w-full bg-gray-50"
                                    />
                                  </div>

                                 
                                      { (employee.role==="admin" &&  accountEditData===null && ( !employee?.category || employee.category==="null")) &&

                                      ( <>

                                          <div className="form-group  md:col-span-3">
                                            <label
                                              className="text-black font-bold"
                                              htmlFor="timeZone"
                                            >
                                              Account Category:
                                            </label>
                                            <select
                                              name="accountType"
                                              className="form-control"
                                              onChange={(e)=>setChangeInCategory(e.target.value)}
                                            >
                                              <option value=""> Select Category</option>
                                              <option  selected={accountEditData && accountEditData?.accountType==="VOICE"} value="VOICE"> VOICE</option>
                                              <option selected={accountEditData && accountEditData?.accountType==="SMS"} value="SMS"> SMS</option>
                                            </select>
                                          </div>


                                          <div className="form-group  md:col-span-3">
                                            <label
                                              className="text-black font-bold"
                                              htmlFor="timeZone"
                                            >
                                              Assigned To:
                                            </label>
                                            <select
                                              name="assignedTo"
                                              className="form-control"
                                            >
                                              <option value=""> Select AM </option>
                                              <option value={`${employee._id}`}> SELF</option>
                                              {amList.map((item) => (
                                                <option key={item._id} value={item._id}>
                                                  {item.name}[{item.productCategory}]
                                                </option>
                                              ))}
                                            </select>
                                          </div>

                                   
                                      </>
                                      )
                                      }

                                <div className="md:col-span-6  m-2">
                                      <label  className="text-black font-bold" htmlfor="remarks">Remarks:</label>
                                      <textarea
                                        type="text"
                                        id="remarks"
                                        name="remarks"
                                        className=" form-control"
                                        defaultValue={  accountEditData?.remarks ?  accountEditData?.remarks  : ''}
                                    
                                      ></textarea>
                                    </div>

                                  {/* <div className="md:col-span-5">
                                                <label className ="text-black font-bold" for="remarks">
                                                  Remarks
                                                </label>

                                                <textarea
                                                  name="remark"
                                                  onChange={changeHandler}
                                                  value={formData.remark}
                                                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                                  rows="4"
                                                ></textarea>
                                              </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer md:col-span-7 flex justify-between  ">
                    <button
                      type="button"
                      className=" bg-red-600 mr-auto text-md flex justify-content  text-white rounded px-4 py-1 font-medium"
                      data-dismiss="modal"
                      onClick={ handleResetForm}
                    >
                      Close
                    </button>

                    <button
                      className=" text-md bg-blue-400 flex justify-content  text-white rounded px-4 py-1 font-medium"
                      type="submit"
                    >
                      {loading ? <Loader /> : "SUBMIT"}
                    </button>
                  </div>
                </div>{" "}
              </form>
            </div>
          </div>

          <div className="col-sm-12">
            
          <div className="flex justify-between mb-1"> <p>Account Details</p>

           { employee.role!=="NOC Manager" && (<button
            type="button"
            className="btn bg-blue-700 text-bold text-sm text-white border-black"
            data-toggle="modal"
            data-target="#addNewAccModal" 
            ref={openAccountModalRef}
            >   Add Account
            <FontAwesomeIcon className="ml-1" icon={faPlus} />
          </button>)}

          </div> 
            <AccountsComp loadingVar={loadCompoent} setAccountEditData={setAccountEditData}  openAccountModalRef={openAccountModalRef}  />
          </div>
        </div>
      </div>
    </Sidebar>
  );
}



