import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button,Zoom} from '@mui/material';
// import { ZoomIn } from '@mui/icons-material';
// import { ThreeDots } from 'react-loader-spinner'
const DialogComponent = ({ open, title, body, button1Text, button2Text, onCancel, handleButtonClicked }) => {
    
    // const [buttonReload,setButtonReload]=useState(false);
   
    
  return (
     
    <Dialog open={open} onClose={onCancel} aria-labelledby="dialog-title"   
    TransitionComponent={Zoom} TransitionProps={{timeout:200}}>
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
    
     <DialogActions>
     {/* { buttonReload ?  
     
     <div className='p-2 mr-4'> 
     <ThreeDots
     visible={true}
     height="40"
     width="40"
     color="#4fa94d"
     radius="9"
     ariaLabel="three-dots-loading"
     wrapperStyle={{}}
     wrapperClass=""
     />
     </div>   : <> */}


     { button2Text &&  <Button  value="2" 
        onClick={(e)=>{ 
           //setButtonReload(true);  
          
          handleButtonClicked(e.target.value); 

        //   const delay = setTimeout(() => {
        //     setButtonReload(false);
        // }, 1000);
        // return () => clearTimeout(delay);

          }} color="error">
          {button2Text} 
          </Button> }
       { button1Text && 
        <Button  value="1" onClick={(e)=> {   handleButtonClicked(e.target.value)



        }} color="primary"> 
          {button1Text}
        </Button>}
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>  
        
        {/* </> } */}
      </DialogActions>  
    </Dialog>
  );
};

DialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  button1Text: PropTypes.string.isRequired,
  
  handleButtonClicked: PropTypes.func.isRequired,
   
};

export default DialogComponent;
