import Sidebar from '../Components/Sidebar';
import ReqCompo from '../Components/ReqCompo';


const CustomerReq = () => { 


    return (<>
    <Sidebar>
        
      <div className='container-fluid'>
        <p>All Routes</p>
        <div className='row'>
        <ReqCompo  />
        </div>
          
      </div>
    </Sidebar>
    </>);
};


export default CustomerReq;