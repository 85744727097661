import React from 'react'
import Sidebar from '../Components/Sidebar'
import TaskCompo from '../Components/TaskCompo'


function MyTasks() {




  return (

    <Sidebar>
             <div className='container-fluid'> 
             
                
             <TaskCompo allorMytask={"mytasks"}/>
             </div>
    </Sidebar>
   
  )
}

export default MyTasks;