import { useMemo,useState,useEffect } from 'react';
import { useSelector } from "react-redux";
import { url } from "../../services/api";
import { apiConnector } from "../../services/apiConnector";
import { useNavigate, useParams } from 'react-router-dom';
import toast from "react-hot-toast";
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';

//Material UI Imports
import {
  Box,
  // Button,
  ListItemIcon,
  MenuItem,
  // Typography,
  // lighten,
} from '@mui/material';

import DialogComponent from '../Components/reusable/DialogComponent'
import { AccountCircle, Send, Delete } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//Icons Imports
// import { AccountCircle, Send } from '@mui/icons-material';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';





const ServicesCompo = (props) => {
  //console.log("accountID",props?.accountIdProps);
  const navigate = useNavigate();
  const employee = useSelector((state) => state.employee);
  const headers = {
      Authorization: `Bearer ${employee.token}`,
    };

  const columns = useMemo(
    () => [
           {
            accessorFn:  (row)=>  employee.role==='admin'? row?.accountId?.companyName :  row?.accountId?.ghostName ,  
            id :'companyName',
            header: 'Company Name',
            size: 30,
          },
      
      
          {
            accessorKey: 'destinationName.name',  
            //id is still required when using accessorFn instead of accessorKey
            header: 'Destination',
            size: 20,
          },
          // {
          //   accessorKey: 'currency.name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          //   enableClickToCopy: true,
          //   filterVariant: 'autocomplete',
          //   header: 'Currency',
          //   size: 50,
          // },
          // {
          //   accessorKey: 'product', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
           
          //   filterVariant: 'autocomplete',
          //   header: 'Product',
          //   size: 50,
          // },
          {
            accessorKey: 'mediaType', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            header: 'Media',
            size: 50,
          },
          {
            accessorKey: 'qualityCategory.name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            enableClickToCopy: true,
            filterVariant: 'autocomplete',
            header: 'Quality',
            size: 50,
          },
          {
           // accessorKey: 'testingStatus', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            accessorFn: (row) =>  row.testingStatus==='ASSIGNED_TO_NOC'? 'IN_PROGRESS':  `${row.testingStatus} ` ,
            id : 'testingStatus',
            filterVariant: 'autocomplete',
            header: 'Status',
            size: 10,
          },
          {
            accessorFn: (row) =>  row.isTopWrokingRoute===true ? "YES" :"NO", 
            id: 'isTopWrokingRoute', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            header: 'TWR',
            size: 3,
          },
          {
            accessorFn: (row) =>  !row.assignedTo?.name ? "NOT ASSGINED" : row.assignedTo.name , //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            id:'assignedTo',
            enableClickToCopy: true,
            filterVariant: 'autocomplete',
            header: 'Assigned NOC',
            size: 30,
          },
   
         
          {
            accessorFn:(row) =>    employee.role==='admin'? row?.pricing :  row?.ghostVisible===true ? row?.ghostPricing : row?.pricing ,
            // accessorKey: 'pricing',
            id:"pricing",
            filterFn: 'between',
            header: 'Price',
            size: 3,
            Cell: ({ cell ,row}) => (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor:
                    cell.getValue() <=0
                      ? theme.palette.error.dark
                      : cell.getValue() >= 1 && cell.getValue() < 10
                        ? theme.palette.warning.dark
                        : theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  maxWidth: '9ch',
                  p: '0.25rem',
                })}
              >
                {
                  cell.getValue()
                }
              </Box>
            ),
          },

          {
            accessorFn: (row) => row.ghostVisible===true ? "V" :"NV", 
            id:'ghostVisibility',
            header:  'Ghost Visible',
            size: 2,
          },

          {
            accessorKey: 'ghostPricing',
            filterFn: 'between',
            header: 'GhostPrice',
            size: 3,
            Cell: ({ cell }) => (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor:
                    cell.getValue() <=0
                      ? theme.palette.error.dark
                      : cell.getValue() >= 1 && cell.getValue() < 10
                        ? theme.palette.warning.dark
                        : theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  maxWidth: '9ch',
                  p: '0.25rem',
                })}
              >
                {cell.getValue() }
              </Box>
            ),
          },  
          
          {
            accessorKey: 'remarks',
            header: 'remarks',
            size: 30,

          },
           
          {
            accessorFn: (row) => new Date(row.updatedAt), //convert to Date for sorting and filtering
            id: 'updateDt',
            header: 'Updated Date',
            filterVariant: 'date',
            filterFn: 'lessThan',
            sortingFn: 'datetime',
            Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
            Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
            muiFilterTextFieldProps: {
              sx: {
                minWidth: '150px',
              },
            },
          },
        
    ],
    [],
  );

  const [reload,setReload]=useState(false);
  const [allAccounts, setAllAccounts] = useState([]);

  

  useEffect(() => {

 
      const params = {
        method: "GET",
        url: (props?.accountIdProps) ?  `${url}/api/services?accountId=${props?.accountIdProps}` :  `${url}/api/services` ,
        headers: headers,
      };
    apiConnector(params)
      .then((response) => {
      //  console.log(response.data);
        // if(employee.role==='admin')
        //   setAllAccounts(response.data);
        // else{
          // console.log("filterd ", response.data.filter((acc) => acc.accountId?._id === props?.accountIdProps))
          // setAllAccounts( response.data.filter((acc) => acc.accountId?._id === props?.accountIdProps));
          setAllAccounts(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching daily progress reports:", error);
        // setLoading(false);
      });
    }, [props?.reload,reload]);








  const table = useMaterialReactTable({
    columns,
    data:allAccounts, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,//showing multiple filter options
    enableColumnOrdering: true,
    enableGrouping: false,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true, // view profile others
    enableRowSelection: false,
    initialState: {
      density: 'compact',
      showColumnFilters: false,
      showGlobalFilter: true,
      columnVisibility: { ghostVisibility: employee.role==='admin'?true:false , ghostPricing : employee.role==='admin'?true:false },
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    // renderDetailPanel: ({ row }) => (
    //   <Box
    //     sx={{
    //       alignItems: 'center',
    //       display: 'flex',
    //       justifyContent: 'space-around',
    //       left: '30px',
    //       maxWidth: '1000px',
    //       position: 'sticky',
    //       width: '100%',
    //     }}
    //   >
       
    //     <Box sx={{ textAlign: 'center' }}>
    //       <Typography variant="h6">Test:</Typography>
    //       <Typography variant="h6"> 
    //         &quot;{row.original.companyName}&quot;
    //       </Typography>
    //     </Box>
    //   </Box>
    // ),
    muiTableBodyRowProps: ({ row ,theme}) => ({
      //conditionally style selected rows
      sx: {
        fontWeight:'bold',// row.getIsSelected() ? 'bold' : 'normal',
        backgroundColor: row?.original?.testingStatus==="PASSED"?'#24A148' : 
        row?.original?.testingStatus==="FAILED"? 'rgba(218, 30, 40, 0.4)' : 
        row?.original?.testingStatus==="ASSIGNED_TO_NOC"?  'rgb(173, 216, 230)' : 
        '#FFFFE0'
      },
    }),
  
    renderRowActionMenuItems: ({ closeMenu,row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          navigate(`/taskTimeline?serviceId=${row?.original?._id}`);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        {/* <Link to={`/reports/${row.original._id}`}>   */}
           View or Create Task
           {/* </Link> */}
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
         if ( props?.openModal) { 
          props?.setShowGhostPriceBox(row.original.ghostVisible);
          props?.setserviceEditData(row.original);
              props?.openModal.current.click();
         }else {
           navigate(`/account?id=${row?.original?.accountId?._id}`);
         }

          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Send />
        </ListItemIcon>
           {  props?.openModal ? "Edit":"View Account"}   
      </MenuItem>,
    
     <MenuItem
       key={2}
       onClick={() => {
        row?.original.testingStatus ==='PASSED' ? handleMarkTWR(row?.original?._id) :
        toast.error("Status must be PASSED for Top Working Route!")
         closeMenu();
       }}
       sx={{ m: 0 }}
     >
       <ListItemIcon>
         <Send />
       </ListItemIcon>
       {row?.original.testingStatus ==='PASSED' ?  row?.original.isTopWrokingRoute ?  "Remove Top Working Route":"Mark  Top Working Route" :"TWR Not Eligible" }
       
     </MenuItem>,
       <MenuItem
       key={3}
       onClick={() => {
        openDialogForMarkStatus(row?.original?._id)
        // row?.original.testingStatus ==='NOT_YET_TESTED' ? openDialogForMarkStatus(row?.original?._id) :
        // toast.error("Status must be NOT_YET_TESTED for Direct Status Change!")

         closeMenu();
       }}
       sx={{ m: 0 }}
     >
       <ListItemIcon>
         <Send />
       </ListItemIcon>
       {/* {row?.original.testingStatus ==='NOT_YET_TESTED' ? "Direct Status Update" :"Not Eligible for Direct Status" } */}
       Direct Status Update
     </MenuItem>,

      <MenuItem
      key={4}
      onClick={() => {
        // Send email logic...
        handleDeleteReq(`${row?.original?._id}`);

        closeMenu();
      }}
      sx={{ m: 0 }}
      >
      <ListItemIcon>
        <Delete />
      </ListItemIcon>
      Delete 
      </MenuItem>,
     
    
    ],
      //No use of now
    // renderTopToolbar: ({ table }) => {
    //   const handleDeactivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('deactivating ' + row.getValue('name'));
    //     });
    //   };

    //   const handleActivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('activating ' + row.getValue('name'));
    //     });
    //   };

    //   const handleContact = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('contact ' + row.getValue('name'));
    //     });
    //   };

    //   return (
    //     <Box
    //       sx={(theme) => ({
    //         backgroundColor: lighten(theme.palette.background.default, 0.05),
    //         display: 'flex',
    //         gap: '0.5rem',
    //         p: '8px',
    //         justifyContent: 'space-between',
    //       })}
    //     >
    //       <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
    //         <MRT_GlobalFilterTextField table={table} />
    //         <MRT_ToggleFiltersButton table={table} />
    //       </Box>
    //       <Box>
    //         <Box sx={{ display: 'flex', gap: '0.5rem' }}>
    //           <Button
    //             color="error"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleDeactivate}
    //             variant="contained"
    //           >
    //             Deactivate
    //           </Button>
    //           <Button
    //             color="success"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleActivate}
    //             variant="contained"
    //           >
    //             Activate
    //           </Button>
    //           <Button
    //             color="info"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleContact}
    //             variant="contained"
    //           >
    //             Contact
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Box>
    //   );
    // },
  });


  const handleMarkTWR=  async (serviceId)=>{

    try{
        let confirm = window.confirm("Confirm to mark the route as TOP WORKING!");
        if(!confirm) return false;
      let response = await apiConnector({
        method: "PUT",
        url: `${url}/api/services/markAsTWR/${serviceId}`,
        bodyData: {
          updatedBy: employee._id
        },
        headers: headers,
      });
      if (response.status === 200) {
         setReload(!reload) ;
        toast.success("Top working Route status updated!");
      }else{
          toast.error("Some Error Occured");
      }
  
    }catch(error){
      console.log(error);
        toast.error("Error "+error?.response?.data?.message);
    }
  
   }
  




   const [dialogOpen, setDialogOpen] = useState(false);
   const [selectedId,setSelectedId] = useState(null);

   const onCancel = (event, reason)=>{
     if (reason && reason === "backdropClick") 
      return;
    setSelectedId(null);
    setDialogOpen(false);

   }





   const openDialogForMarkStatus = (id)=>{
      setSelectedId(id);
      setDialogOpen(true)
   }

 
   const handleDirectMarkStatus=  async (value)=>{
          setDialogOpen(false);
         
          let statusToMark = "";
        
          if (value === "1") {
            statusToMark = "PASSED";
          } else if (value === "2") {
            statusToMark = "FAILED";
          } else {
            toast.error("An error occurred: invalid value");
            return; // Exit the function early if an invalid value is provided
          }
         // toast.error(`value selected ${statusToMark}`)
         // return false;
       
    try{
      //  let confirm = window.confirm("Confirm to mark the route status! sdsdf",serviceId);
        //if(!confirm) return false;
      let response = await apiConnector({
        method: "PUT",
        url: `${url}/api/services/markDirectPassFailed/${selectedId}`,
        bodyData: {
          statusToMark: statusToMark
        },
        headers: headers,
      });
      if (response.status === 200) {
         setReload(!reload) ;
        toast.success("Top working Route status updated!");
      }else{
          toast.error("Some Error Occured");
      }
  
    }catch(error){
      console.log(error);
        toast.error("Error "+error?.response?.data?.message);
    }
  
    setSelectedId(null);


   }
  

 const handleDeleteReq = async(idToDelete)=>{
      const confirm = window.confirm("Confirm to delete the selected Route?")
      if(!confirm)
        return false;
    try {
      const response = await apiConnector({
        method: "DELETE",
        url: `${url}/api/services/${idToDelete}`,  
        headers: headers,
      });

      // Check if the API call was successful
      if (response.status === 200) {
        toast.success(`${response?.data?.message}`);
        setReload(!reload) ;
      }else{
        toast.error("Some error occured!")
      }
    } catch (error) {
      toast.error(`Error : ${error?.response?.data?.message}`);
    }
  }


  return (<>  
  <DialogComponent
        open={dialogOpen}
        title="Confirmation"
        body="Please choose the status for direct Status change of the Route."
        button1Text="MARK PASSED"
        button2Text="MARK FAILED"
        onCancel={onCancel}
        handleButtonClicked={handleDirectMarkStatus}
       
      />
  <MaterialReactTable table={table} /> </>);
};

//Date Picker Imports - these should just be in your Context Provider
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const ExampleWithLocalizationProvider = (accountIdProps) => (
  //App.tsx or AppProviders file
 
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ServicesCompo accountIdProps={accountIdProps?.accountIdProps} reload={accountIdProps?.reload} openModal={accountIdProps?.openModal}
      serviceEditData={ accountIdProps?.serviceEditData} setserviceEditData={accountIdProps?.setserviceEditData}
      setShowGhostPriceBox={ accountIdProps?.setShowGhostPriceBox}
    />
  </LocalizationProvider>
);

export default ExampleWithLocalizationProvider;

// function useGetAccounts() {
//     const employee = useSelector((state) => state.employee);
//     const headers = {
//         Authorization: `Bearer ${employee.token}`,
//       };
//     return useQuery({
//       queryKey: ['users'],
//       queryFn: async () => {
//         // Function to fetch users data
//         const fetchUsersData = () => {
//           const params = {
//             method: "GET",
//             url: `${url}/api/account`,
//             headers: headers,
//           };
//           // Returning a promise for the API call
//           return apiConnector(params);
//         };
  
//         try {
//           // Call the function to fetch users data
//           const response = await fetchUsersData();
//     //       roleFilter
//     // ? response.data.filter((user) => user.role === "NOC Manager")
//     // : response.data;
//         // if("NOC Manager"===filterRole)
//         // {
//           // return response.data.filter((user) => user.role === "NOC Manager");

//         // }
//         // else
//           // return response.data.filter((user) => user.role === filterRole);
//           return response.data;
//         } catch (error) {
//           // Handle error
//           console.error("Error fetching Users:", error);
//           throw new Error(error); // Rethrow the error to be handled by useQuery
//         }
//       },
//       refetchOnWindowFocus: false,
//     });
//   }

 