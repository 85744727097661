import { useMemo,useState,useEffect ,useRef} from 'react';
import { useSelector } from "react-redux";
import { url } from "../../services/api";
import { apiConnector } from "../../services/apiConnector";
import { useNavigate } from 'react-router-dom';
// import toast from "react-hot-toast";
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';

//Material UI Imports
import {
  Box,
  // Button,
  ListItemIcon,
  MenuItem,
  Typography,
  // lighten,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';

import { AccountCircle, Send } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

 
const MapperCompo = () => {
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        accessorKey: 'destination.name',  
         header: 'Destination',
        size: 20,
      },
 
      {
        accessorKey: 'category.name',  
        header: 'Quality Category',
        size: 10,
      },
      {
        accessorKey: 'product',  
        header: 'Product',
        size: 10,
      },
      {
        accessorKey: 'services.length',  
        header: 'Routes Count',
        size: 3,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              borderRadius: '0.25rem',
              maxWidth: '9ch',
              fontWeight:'bold',
              p: '0.25rem',
            })}
          >
           { cell.getValue() }
          </Box>
        ),
      },
      {
        accessorKey: 'req.length',
        header: 'Requirements Count',
        size: 3,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              borderRadius: '0.25rem',
              maxWidth: '9ch',
              fontWeight:'bold',
              p: '0.25rem',
            })}
          >
           { cell.getValue() }
          </Box>
        ),
      },
      
    ],
    [],
  );



  // useState vars
  const [allAccounts, setAllAccounts] = useState([]);
  const employee = useSelector((state) => state.employee);

  const headers = {
      Authorization: `Bearer ${employee.token}`,
    };

  useEffect(() => { 
      const params = {
        method: "GET",
        url:   `${url}/api/mapper`,
        headers: headers,
      };

      if(params.url!==""){
      apiConnector(params)
        .then((response) => {
          setAllAccounts(response.data);
        })
        .catch((error) => {
          console.error("Error fetching daily progress reports:", error);
          // setLoading(false);
        });
    }

    }, []);

  const table = useMaterialReactTable({
    columns,
    data:allAccounts, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,//showing multiple filter options
    enableColumnOrdering: true,
    enableGrouping: false,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: false, // view profile others
    enableRowSelection: false,
    initialState: {
      density: 'compact',
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-around',
          left: '30px',
          maxWidth: '1000px',
          position: 'sticky',
          width: '100%',
        }}
      >
         
        <Box sx={{  display: '' , textAlign: '' }}>
          <Typography variant="mr-1 flex" > 



          <div className="overflow-x-auto mr-2  text-sm">
                

                  <TableContainer  elevation={20} sx={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <Table sx={{ backgroundColor: 'white', border: '1px solid black' }} size="small" aria-label="compact table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={4} className='bg-blue-300' sx={{ textAlign: 'center', border: '1px solid black'   ,fontWeight: 'bold', fontSize: 'small'}}>Routes</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: '1px solid black' }}>Company Name</TableCell>
            {employee.role === 'admin' && <TableCell sx={{ border: '1px solid black' }}>Ghost Name</TableCell>}
            <TableCell sx={{ border: '1px solid black' }}>Pricing</TableCell>
            {employee.role === 'admin' && <TableCell sx={{ border: '1px solid black' }}>Ghost Price</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {row?.original?.services.map((item, index) => (
            <TableRow key={index}>
              <TableCell sx={{ border: '1px solid black' }}>
                {employee.role === 'admin' ? item?.accountDetails.companyName : item?.accountDetails.ghostName}
              </TableCell>
              {employee.role === 'admin' && (
                <TableCell sx={{ border: '1px solid black' }}>
                  {item?.accountDetails?.ghostName}
                </TableCell>
              )}
              <TableCell sx={{ border: '1px solid black' }}>
                {employee.role === 'admin' ? item?.pricing : item?.ghostVisible === true ? item?.ghostPricing : item?.pricing}
              </TableCell>
              {employee.role === 'admin' && (
                <TableCell sx={{ border: '1px solid black' }}>
                  {item?.ghostPricing}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>


    <div className="overflow-x-auto  ">
     

      <TableContainer component={Paper} elevation={3} sx={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' ,  fontSize: 'small'}}>
      <Table sx={{   backgroundColor: 'white', border: '1px solid black' }} size="small" aria-label="compact table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}  className='bg-red-300'  sx={{ textAlign: 'center', border: '1px solid black' ,fontWeight: 'bold', fontSize: 'small' }}>Request</TableCell>
          </TableRow>
          <TableRow >
            <TableCell sx={{ border: '1px solid black' }}>Company Name</TableCell>
            {employee.role === 'admin' && <TableCell sx={{ border: '1px solid black' }}>Ghost Name</TableCell>}
            <TableCell sx={{ border: '1px solid black' }}>Price Range</TableCell>
            <TableCell sx={{ border: '1px solid black' }}>Volume</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row?.original?.req.map((item, index) => (
            <TableRow key={index}>
              <TableCell sx={{ border: '1px solid black' }}>
                {employee.role === 'admin' ? item?.accountDetails.companyName : item?.accountDetails.ghostName}
              </TableCell>
              {employee.role === 'admin' && (
                <TableCell sx={{ border: '1px solid black' }}>
                  {item?.accountDetails?.ghostName}
                </TableCell>
              )}
              <TableCell sx={{ border: '1px solid black' }}>
                {item?.pricingRange[0]} - {item?.pricingRange[1]}
              </TableCell>
              <TableCell sx={{ border: '1px solid black' }}>
                {item?.volume}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </div>
             
          
           </Typography> 
       
        </Box>
      </Box>
    ),

    renderRowActionMenuItems: ({ closeMenu,row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          
          navigate(`/taskTimeline?serviceId=${row?.original?.service?._id}`);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
           View Task Thread Group 
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
         
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Send />
        </ListItemIcon>
        Delete
      </MenuItem>,
    ],

 

  });



  


  return  (  
    <>  
    <MaterialReactTable table={table} />
    </> );
};


const ExampleWithLocalizationProvider = (loadingVar) => (


  <LocalizationProvider dateAdapter={AdapterDayjs}   >

    
    <MapperCompo loadingVar={loadingVar} /> 
  </LocalizationProvider>
);

export default ExampleWithLocalizationProvider;




 