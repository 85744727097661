

import Sidebar from "../Components/Sidebar";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import {url} from '../../services/api'
import io from 'socket.io-client';
import { Grid, Box, Card, Typography ,Badge, Button,Paper} from '@mui/material';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './notification.css'
import { apiConnector } from "../../services/apiConnector";
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";

import EmployeeDetailsDialog from "../Components/reusable/EmployeeDetailsDialog";


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#ffee58',
    color: 'black',
    top: 8,
    right: 60,
    borderRadius: '4px',
    padding: '0 4px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
  },
}));



const NotificationData =({notification,handleEmployeeClick})=> {
  
  const navigate = useNavigate();

  return(
  <>
  <Typography sx={{fontWeight:'bold',fontSize:'14px'}} variant="" component="div">
    {notification.message}  
  </Typography>
 
  <Typography  sx={{fontSize:'13px'}} className="text-sm">
    Actioned By : <button onClick={()=> handleEmployeeClick(`${notification.emitter._id}`)} >{notification.emitter?.name}</button>  at   {new Date(notification.createdAt).toLocaleString()}
  </Typography>
  {  (notification.notificationType==='ROUTE_PASSED'  ) && <Button 
   onClick={()=>     navigate(`/taskTimeline?serviceId=${notification?.serviceId}`) }  variant={'contained'}  sx={{ color: 'white', fontSize: '10px', fontWeight: 'bold'  }} size="small">View Route</Button> }
  {  (notification.notificationType==='TASK_CREATION'  ) && <Button size="small" 
  onClick={()=>     navigate(`/taskTimeline?serviceId=${notification?.serviceId}`)} 
  variant={'contained'}  sx={{ color: 'white', fontSize: '10px', fontWeight: 'bold'  }}
  >View Task</Button> }
   {  (notification.notificationType==='ROUTING_TASK'  ) && <Button size="small" 
  onClick={()=>     navigate(`/routingTaskThread?routingTaskId=${notification?.serviceId}`)} 
  variant={'contained'}  sx={{ color: 'white', fontSize: '10px', fontWeight: 'bold'  }}
  >View Routing Task</Button> }

  {  (notification.notificationType==='REQ_CREATION' || notification.notificationType==='AC_CREATION' ) && <Button 
   variant={'contained'}  sx={{ color: 'white', fontSize: '10px', fontWeight: 'bold'  }} size="small" 
    onClick={()=>   navigate(`/account?id=${notification?.accountId}`) }
  >View Account</Button> }
</>
)};

 

const NotificationList = ({ notifications,handleEmployeeClick }) =>   {

    // const navigate = useNavigate();

    return  (
  <Box className="p-1"  sx={{ width: '100%' }}>
  
  <TransitionGroup>
    {notifications.map((notification,index) => (
       <CSSTransition  key={index}    timeout={500}  classNames="fade"  sx={{ width: "100%" }}   > 
      <StyledBadge
        key={notification.id} 
        badgeContent={  notification?.notificationType ==='AC_CREATION' ?  `ACCOUNT CREATION-${notification.product.toUpperCase()}` : 
        `${notification?.notificationType}-${notification.product.toUpperCase()}`   } // Assuming `type` is either "voice" or "sms"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ width: '100%',}}
      >
        <Paper  className="mb-2 p-3" elevation={20} style={{       width: '100%', height: 'auto', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
              <NotificationData notification={notification} handleEmployeeClick={handleEmployeeClick}/>
        </Paper>   
        </StyledBadge>
        </CSSTransition>
    ))}
    </TransitionGroup>
  </Box>
);}



 




export default function Notification() {

  const navigate = useNavigate();

  const [notifications, setNotifications]= useState([]);
   const [reload,setReload]=useState(false);
  const employee = useSelector((state) => state.employee);
  const [ unreadNotifications,setUnread ]= useState([]);
  const [readNotifications,setRead ]= useState([]);
  const[mapperNotifcation,setMapperNotification]=useState([]);

  const headers = {
    Authorization: `Bearer ${employee.token}`,
  };

  useEffect(() => {
    // Define the parameters for the apiConnector function
    const params = {
      method: 'GET',
      url: `${url}/api/notification`,
      headers:  headers,
    };
  
    // Call the apiConnector function with the specified parameters
    apiConnector(params)
      .then(response => {
        // Handle successful response
        setMapperNotification(response.data.filter(item =>item.notificationType==='NEW_MAPPING').slice(0,10))
        setNotifications(response.data.filter(item =>item.notificationType!=='NEW_MAPPING').slice(0,20));
        
      })
      .catch(error => {
        // Handle error
        console.error('Error fetching Notification', error);
        
      });


  }, [reload]);
 

  useEffect(() => {
     const params = {
      method: 'GET',
      url: `${url}/api/notification/personal`,
      headers:  headers,
    };
     apiConnector(params)
      .then(response => {
        setUnread(response.data ? response.data.filter((notif) => !notif.readBy.includes(employee._id)) : []);
        setRead(response.data ? response.data.filter((notif) => notif.readBy.includes(employee._id)) : []);
        
      })
      .catch(error => {
        // Handle error
        console.error('Error fetching Notification', error);
        
      });

  }, [reload]);


  useEffect(() => {
    const socket = io( `${url}`, {
      query: {  userId:employee._id }  
    });

    let socketType;
    if(employee.role==='admin'){
        socketType="admin"
    }else if(employee.role==='Account Manager'){
        socketType=`AM_${employee.category}`
    } else{
        socketType='personal'
    }
   // console.log("socketType defined as -----",socketType)
    
    socket.on(socketType, (data) => {
       setReload(!reload);
     });

    return () => {
      socket.disconnect();
    };
  }, [employee._id,reload]);

   

  const markAsRead = async (id) => {

     
    try{
    let response = await apiConnector({
      method: "PUT",
      url: `${url}/api/notification/read/${id}`,
      bodyData: {  },
      headers: headers,
    });
    if (response.status === 200) {
      
      toast.success(`${response?.data?.message}`);
      setReload(!reload);
    }else{
        toast.error("Unable to mark Read");
    }
  }catch(error){
    console.log(error);
      toast.error("Error "+error?.response?.data?.message);
  }



  };

  

  const [selectedEmpId,setSelectedEmpId]=useState(null);

  const handleCloseDialog = () => {
    setSelectedEmpId (null);
  }

  const handleEmployeeClick =(empid)=>{
    setSelectedEmpId(empid);
}


  return (
    <Sidebar>
      <div className="container-fluid">
        <h2>Notifications</h2>

        <Grid container>
          <Grid item xs={6}>
            <Box className="pr-5">
              <Typography variant="h5" component="div" className="mb-4">
                Unread Notifications
              </Typography>
              <TransitionGroup>
                {unreadNotifications.map((notification, index) => (
                  <CSSTransition  key={index}    timeout={500}  classNames="fade"  sx={{ width: "100%" }}   >
                    <Badge
                      color="error"
                      badgeContent={"Unread"}
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      style={{ width: "100%" }}
                      showZero
                      className="mb-2"
                    >
                      <StyledBadge
                        key={notification.id}
                        badgeContent={
                          notification?.notificationType === "AC_CREATION"
                            ? `ACCOUNT CREATION-${notification.product.toUpperCase()}`
                            : `${
                                notification?.notificationType
                              }-${notification.product.toUpperCase()}`
                        }
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        style={{ width: "100%" }}
                      >
                        <Card
                          elevation={20}
                          className="w-full bg-black-500 p-3"
                          sx={{ width: "100%", backgroundColor: "#90caf9" }}
                        >
                          <NotificationData notification={notification} handleEmployeeClick={handleEmployeeClick} />
                          <Button
                            sx={{ color: "black" }}
                            onClick={() => markAsRead(notification._id)}
                          >
                            Mark Read
                          </Button>
                        </Card>

                        {/* </Paper>  */}
                      </StyledBadge>
                    </Badge>
                  </CSSTransition>
                ))}
              </TransitionGroup>

              <Typography variant="h5" component="div" className="mt-8 mb-4">
                Read Notifications
              </Typography>
                <NotificationList notifications={readNotifications} handleEmployeeClick={handleEmployeeClick} />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" component="div" className="mb-4">
              Mapping Notifications   &nbsp;

              <Button size="small" 
                  onClick={()=>     navigate(`/mappingHistory`)} 
                    variant={'contained'}  sx={{ color: 'white', fontSize: '10px', fontWeight: 'bold'  }}
                    >View All</Button> 
            </Typography>

            <NotificationList notifications={mapperNotifcation} handleEmployeeClick={handleEmployeeClick}/>

            <Grid item xs={12}>
            <Typography variant="h5" component="div" className="mb-4">
              All Notifications
            </Typography>

            <NotificationList notifications={notifications} handleEmployeeClick={handleEmployeeClick}/>
          </Grid>
          </Grid>
       
        </Grid>
        <EmployeeDetailsDialog empid={selectedEmpId} onClose={handleCloseDialog} />
      </div>
    </Sidebar>
  );
}
