import { useMemo,useState,useEffect ,useRef} from 'react';
import { useSelector } from "react-redux";
import { url } from "../../services/api";
import { apiConnector } from "../../services/apiConnector";
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';

//Material UI Imports
import {
  Box,
  // Button,
  ListItemIcon,
  MenuItem,
  // Typography,
  // lighten ,
  Table, TableBody, TableCell, TableHead, TableRow, Paper
} from '@mui/material';

import { AccountCircle, Send } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

 
const EmployeeGroupCompo = () => {
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',  
         header: 'Group Name',
        size: 20,
      },
      {
        accessorKey: 'role',  
        header: 'Role',
        size: 20,
      },
      {
        accessorKey: 'productCategory',  
        header: 'Category',
        size: 10,
      },
      {
        accessorFn: (row) =>  row.empList?.length  , //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        id:'empcount',
        enableClickToCopy: true,
        header: 'Emp Count',
        size: 30,
      },
      {
        accessorFn: (row) =>  row.createdBy?.name, 
        id : 'createdBy',
        header:'CreatedBy',
        enableEditing: false,
        Edit: () => null,
        size:20,
      },

      {
        accessorFn: (row) => new Date(row.createdAt), //convert to Date for sorting and filtering
        id: 'Created_At',
        header: 'Created Date',
        enableEditing: false,
        Edit: () => null,
        filterVariant: 'datetime-range',
        Cell: ({ cell }) => `${cell.getValue().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })} ${cell
          .getValue()
          .toLocaleTimeString()}`,//cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        muiFilterTextFieldProps: {
          sx: {
            minWidth: '150px',
          },
        },
      },
      {
        accessorFn: (row) =>  row.updatedBy?.name, 
        id : 'updatedBy',
        header:'UpdatedBy',
        enableEditing: false,
        Edit: () => null,
        size:20,
      }, 

      {
        accessorFn: (row) => new Date(row.updatedAt), //convert to Date for sorting and filtering
        id: 'Updated_At',
        header: 'Updated Date',
        enableEditing: false,
        Edit: () => null,
        filterVariant: 'datetime-range',
        Cell: ({ cell }) => `${cell.getValue().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })} ${cell
          .getValue()
          .toLocaleTimeString()}`,//cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        muiFilterTextFieldProps: {
          sx: {
            minWidth: '150px',
          },
        },
      },

      {
        accessorKey: 'status',
        header: 'Status',
        size: 3,
        enableEditing: false,
        Edit: () => null,
        //custom conditional format and styling
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() ===false
                  ? theme.palette.error.dark : theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
             // maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() ?"ENABLED":"DISABLED"}
          </Box>
        ),
      },
      
    ],
    [],
  );



  // useState vars
 
  const [allAccounts, setAllAccounts] = useState([]);
  const employee = useSelector((state) => state.employee);

  const[empGroupType,setEmpGroupType]=useState("");
  const[activeNOCList,setActiveNOCList]=useState([]);
  const[selectedEmpList,setSelectedEmpLIst] = useState([]);
  const[loadComponent,SetLoadComponent]=useState(false);

  const modalCloseButtonRef = useRef();
  const formResetRef = useRef();
  

 
  const headers = {
      Authorization: `Bearer ${employee.token}`,
    };

  useEffect(() => { 
      const params = {
        method: "GET",
        url: employee.role==='admin'? `${url}/api/empgroup`:employee.category==='VOICE'?`${url}/api/empgroupActive_VOICE`:employee.category==='VOICE'?`${url}/api/empgroupActive_VOICE`:"",
        headers: headers,
      };

      if(params.url!==""){
      apiConnector(params)
        .then((response) => {
          setAllAccounts(response.data);
        })
        .catch((error) => {
          console.error("Error fetching daily progress reports:", error);
          // setLoading(false);
        });
    }

    }, [loadComponent]);

  const table = useMaterialReactTable({
    columns,
    data:allAccounts, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,//showing multiple filter options
    enableColumnOrdering: true,
    enableGrouping: false,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true, // view profile others
    enableRowSelection: false,
    initialState: {
      density: 'compact',
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-around',
          left: '30px',
          maxWidth: '1000px',
          position: 'sticky',
          width: '100%',
        }}
      >
         
        <Box boxShadow={8} sx={{  display: '' , textAlign: '' }}>
        <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>S No.</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

        {row?.original?.empList.map((item,index)=>(
           <TableRow key={index}> 
              <TableCell>{index+1}</TableCell>
              <TableCell>{item?.name}</TableCell>
              <TableCell>{item?.email}</TableCell>
              <TableCell>{item?.phone}</TableCell>  
              <TableCell>{item?.role}</TableCell>  
            </TableRow>
            ))  } 
 
        
           
        </TableBody>
      </Table>
    </Paper>
       

        </Box>
      </Box>
    ),
    renderRowActionMenuItems: ({ closeMenu,row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          
            alert("Group cant be edited! "+row?.original?._id);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
           Edit Group 
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          handleDisable(row?.original._id)
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Send />
        </ListItemIcon>
       { row?.original?.status ? "DISABLE" : "ENABLE"}
      </MenuItem>,
    ],

 

  });



  useEffect(() => {
    const params = {
      method: "GET",
      url: (empGroupType==='VOICE') ? `${url}/api/employeeActiveNOC_VOICE` : 
      (empGroupType==='SMS')? `${url}/api/employeeActiveNOC_SMS` :"" ,
      headers: headers,
    };
    if(params.url!==""){ 
  apiConnector(params)
    .then((response) => {
      setActiveNOCList(response.data);
    })
    .catch((error) => {
      console.error("Error fetching daily progress reports:", error);
      // setLoading(false);
    });
  }else{
    setActiveNOCList([]);
  } 
}, [empGroupType]);



const handleCheckBoxChecked =(e)=>{
const {checked,value} = e.target;
if(checked){
    setSelectedEmpLIst((prev)=>[...prev,value]);
}else{
  setSelectedEmpLIst((prev)=> prev.filter(item=>item!==value));
}

}

const handleEmpGroupSubmit =async (e)=>{
  e.preventDefault();
  const formdata = new FormData(e.currentTarget);
  const _name = formdata.get('name');
  const _productCategory=formdata.get('productCategory');
  const _empList = formdata.get('empList');
  if(selectedEmpList.length<2){
  alert("Minimum 2 Employees are required for group! ")
    return false;
  }

  //console.log(" _name",_name ," _productCategory", _productCategory," _empList",_empList )
  try {
  let response = await apiConnector({
    method: "POST",
    url: `${url}/api/empgroup`,
    bodyData: {
      name:"GROUP_"+_name,
      productCategory:_productCategory,
      empList:selectedEmpList,
      createdBy:employee._id,
    },
    headers: headers,
  });
  if (response?.status===201) {
    toast.success("Group Created Successfully.");
      modalCloseButtonRef.current.click();
      formResetRef.current.reset();
    SetLoadComponent(!loadComponent);
    setSelectedEmpLIst([]);
  }else{
    toast.success("Some Error Occured!.");
  }
  } catch (error) {
  if (error?.response?.data?.message) {
    toast.error(error?.response?.data?.message);
  }
  }
}


const handleModalCloseButton = ()=>{
  formResetRef.current.reset();
setSelectedEmpLIst([]);
}



const handleDisable = async (userId) =>  {
  
  try {
    const response = await apiConnector({
      method: "PUT",
      url: `${url}/api/empgroup/disable/${userId}`, // Assuming user._id exists
      headers: headers,
    });

    // Check if the API call was successful
    if (response.status === 200) {
     
      toast.success(`${response?.data?.message}`);
       SetLoadComponent(!loadComponent);
    }else{
      toast.error("Some error occured!")
    }
  } catch (error) {
    toast.error(`Error : ${error?.response?.data?.message}`);
  }

}



  return  (  
    <>  
    <div
            className="modal  fade "
            id="addNewAccModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addNewAccModalTitle"
            aria-hidden="true"
            data-backdrop="static"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <form
               onSubmit={handleEmpGroupSubmit}
               ref={formResetRef}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h2 className="font-semibold text-xl text-gray-600">
                      New Account Addition
                    </h2>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row    p-1  flex items-center justify-center ">
                      <div className="container  ">
                        <div>
                          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-2 mb-6">
                            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                              <div className="lg:col-span-4">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                
                                <div className="md:col-span-3">
                                <div className="form-group ">
                                  <label > Group Name</label>

                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">GROUP_</div>
                              </div>
                              <input type="text" className="form-control"  placeholder="Enter Group name" id="groupName"
                                                          name="name"/>
                            </div>

                                </div>

                               </div>

                                  <div className=" md:col-span-3">
                                   
                                    <div className="form-group ">
                                      <label >Product Category</label>
                                      <select
                                        id="productCategory"
                                        name="productCategory"
                                        className="form-control"
                                        onChange={(e) => setEmpGroupType(e.target.value)}
                                      >
                                        <option value="">Select Category</option>
                                        <option value="VOICE">VOICE</option>
                                        <option value="SMS">SMS</option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="form-check md:col-span-3">
                                    <label   >Employee List</label>
                                    <br />

                                    {activeNOCList.map((item,index) => (
                                      <span key={index}>
                                        <input
                                          id={item._id}
                                          className="form-check-input"
                                          type="checkbox"
                                          name="empList"
                                          value={item._id}
                                          onChange={handleCheckBoxChecked}
                                        />
                                        <label
                                          className="form-check-label"
                                         
                                        >
                                          {item.name}[{item.productCategory}]
                                        </label>
                                        <br />
                                      </span>
                                    ))}
                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer md:col-span-7 flex justify-between  ">
                    <button
                      type="button"
                      className=" bg-red-600 mr-auto text-md flex justify-content  text-white rounded px-4 py-1 font-medium"
                      data-dismiss="modal"
                      ref={modalCloseButtonRef}
                      onClick={handleModalCloseButton}
                    >
                      Close
                    </button>

                    <button
                      className=" text-md bg-blue-400 flex justify-content  text-white rounded px-4 py-1 font-medium"
                      type="submit"
                    >SUBMIT
                      {/* {loading ? <Loader /> : "SUBMIT"} */}
                    </button>
                  </div>
                </div>{" "}
              </form>
            </div>
          </div>


          <button
            type="button"
            className="btn btn-primary "
            data-toggle="modal"
            data-target="#addNewAccModal"
          >
            Create Group
            {/* <FontAwesomeIcon className="ml-1" icon={faPlus} /> */}
          </button>
    <MaterialReactTable table={table} />

    </> );
};


const ExampleWithLocalizationProvider = () => (

  <LocalizationProvider dateAdapter={AdapterDayjs}   >
    <EmployeeGroupCompo  /> 
  </LocalizationProvider>
);

export default ExampleWithLocalizationProvider;




 