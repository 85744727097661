import React from 'react';
import Sidebar from "../Components/Sidebar";
import ServicesCompo from "../Components/ServicesCompo";

const Routess = () => {
    return (
    <Sidebar>
      <div className='container-fluid'>
        <p>All Routes</p>
        <div className='row'>
        <ServicesCompo  />
        </div>
      </div>  
    </Sidebar>
  );
};

export default Routess;

