import React ,{useEffect, useState} from 'react'
import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Slide ,Box,
    Table, TableBody, TableCell, TableHead, TableRow, Paper
} from '@mui/material'
import { apiConnector } from "../../../services/apiConnector";
import { url } from "../../../services/api";
import { useSelector } from "react-redux";


function EmployeeDetailsDialog({empid,onClose}) {

    const [empDetails,setEmpDetails]=useState(null);
    const employee = useSelector((state) => state.employee);
    const headers = {
        Authorization: `Bearer ${employee.token}`,
      };
    const fetchDetails= async()=>{
        const params = {
            method: "GET",
            url:  `${url}/api/employee/${empid}`  ,
             headers: headers,
          };
      
          apiConnector(params)
            .then((response) => {

                setEmpDetails([response.data]);
                // console.log ("Data----->",  [response.data])

                // if([response.data].length===0){
                //   fetchDetailsGroup();
                // }
              
            })
            .catch((error) => {
              fetchDetailsGroup();
              // console.error("", error);
              
            });
    }


    const fetchDetailsGroup= async()=>{
        const params = {
            method: "GET",
            url:  `${url}/api/empgroup/${empid}`  ,
             headers: headers,
          };
      
          apiConnector(params)
            .then((response) => {
                setEmpDetails(response.data.empList);
              
            })
            .catch((error) => {
              console.error("Error fetching details:", error);
              
            });
    }

    useEffect(()=>{
            if(empid){
                fetchDetails(); 
            }

            // if(empid && !empDetails){
            //     fetchDetailsGroup();
            // }


    },[empid])

  return (
   <Dialog open={!!empid} onClose={onClose} sx={{mt:2, pb:5}}  PaperProps={{style:{height:'300px'}}} TransitionComponent={Slide}
   
   TransitionProps={{direction:'up'}}> 
    {
        // empDetails? 
        <> 
                <DialogTitle sx={{mt:2,pb:2}}>
                    {/* {empDetails.lenght.name} */} Employee Details
                </DialogTitle>
                <DialogContent>
                <Box boxShadow={8} sx={{  display: '' , textAlign: '' }}>
        <Paper>
      <Table>
        <TableHead>
          <TableRow>
            
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

        { empDetails?.map((item,index)=>(
           <TableRow key={index}> 
              
              <TableCell>{item?.name}</TableCell>
              <TableCell>{item?.email}</TableCell>
              <TableCell>{item?.phone}</TableCell>  
              <TableCell>{item?.role}</TableCell>  
            </TableRow>
            ))  } 
 
        
           
        </TableBody>
      </Table>
    </Paper>
       

        </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color='primary'>Close</Button>
                </DialogActions>


        </> 
        // : <DialogContent>Loading...</DialogContent>


    }

   </Dialog>
  )
}

export default EmployeeDetailsDialog