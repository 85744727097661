import React from 'react'
import Sidebar from '../Components/Sidebar'
import TaskCompo from '../Components/TaskCompo'


function Tasks() {




  return (

    <Sidebar>
             <div className='container-fluid'> 
             <TaskCompo  />
             </div>
    </Sidebar>
   
  )
}

export default Tasks;