import React, { useState, useEffect ,useMemo,useRef} from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Components/Sidebar";
import ReportCompo from "../Components/ReportsCompo"

import { apiConnector } from "../../services/apiConnector";
  import moment from "moment";
import { url } from "../../services/api";

import {
  MaterialReactTable,
  // useMaterialReactTable,
} from 'material-react-table';

const Reports = () => {


  const employee = useSelector((state) => state.employee);
  const headers = {
    Authorization: `Bearer ${employee.token}`,
  };

  const [activeTab, setActiveTab] = useState("Daily Progress Report");
  const [loginHistory, setLoginHistory] = useState([]);
  const[loginHistoryDate,setLoginHistoryDate]=useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(true);
 
  
  useEffect(() => {
    const params = {
      method: "GET",
    //  url: `${url}/api/report/login-history`,
      url: `${url}/api/report/login-historyByDate/${loginHistoryDate}`,
      headers: headers,
    };

    apiConnector(params)
      .then((response) => {
        setLoginHistory(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching login history:", error);
        setLoading(false);
      });
  }, [loginHistoryDate]);

const loginHistroyColumns = useMemo(
  () => [
    {
      accessorKey: 'employeeName', 
      header: 'Employee Name',
      length: 50,
    },
    {
      accessorKey: 'employeeRole', 
      header: 'Role',
      length: 50,
    },
    {
      accessorKey: 'employeeEmail', 
      header: 'Email',
      length: 50,
    },
    {
      accessorKey: 'loginDate', 
      header: 'Login Date',
      length: 50,
    },
    {
      accessorKey: 'loginTime',
      header: 'Login Time',
      size: 50,
    },
    
  ],
  [],
);



return (
  <Sidebar>
    <div className="container">
      <div className="col-sm-12">
        <div className="">
          <div className="flex ">
            {/* Top toggle buttons */}
            <div
              className={`ml-1 mr-6 text-md font-semibold cursor-pointer rounded-md px-4 py-2  ${
                activeTab === "Daily Progress Report"
                  ? "drop-shadow-xl   bg-white text-[#6139C7]  border border-[#6139C7] "
                  : "bg-white text-gray-400   border border-gray-200"
              }`}
              onClick={() => setActiveTab("Daily Progress Report")}
            >
              {employee.role === "admin"
                ? "Daily Progress Report"
                : "My Reports"}
            </div>

            <div
              className={`ml-1 font-semibold cursor-pointer rounded-md px-4 py-2 sm:px-1  md:text-md lg:text-md xl:text-md    ${
                activeTab === "Employee Login Logs"
                  ? "drop-shadow-xl   bg-white text-[#6139C7]  border border-[#6139C7] "
                  : "bg-white text-gray-400   border border-gray-200"
              }`}
              onClick={() => setActiveTab("Employee Login Logs")}
            >
              {employee.role === "admin"  ? "Employee Login Logs"    : "My Login Logs"}
            </div>
            {/* Top toggle buttons */}
          </div>

          <div className="flex container row">
            {activeTab === "Daily Progress Report" && (
              <div className="w-full bg-white  rounded-md mt-3">
                <div className="mt-2">
                  {/* <div className="w-full p-0 py-1 text-black">
                    <div className="flex justify-end w-full">
                      <div className="flex">
                       
                        
                       
                      
                      </div>
                      <div />
                    </div>
                  </div> */}

                  
                   <ReportCompo/>
                </div>
              </div>
            )}

            {activeTab === "Employee Login Logs" && (
              <div className="w-full   rounded-md mt-3">
                <div className="mt-2">
                  <div className="flex row col-sm-6">
                    <label htmlFor="company_name" className="col-sm-4   ">
                      
                      Login History Date :
                    </label>
                    <input
                      onChange={(e) => setLoginHistoryDate(e.target.value)}
                      value={loginHistoryDate}
                      //  onChange={ (e)=> console.log(e.target.value)}
                      name="date"
                      type="date"
                      className="col-sm-6 h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                    />
                  </div>
                  {loading ? (
                    <center> Loading.... </center>
                  ) : loginHistory.length === 0 ? (
                    <tr>
                      <center>No login history found</center>
                    </tr>
                  ) : (
                    <>
                      <MaterialReactTable
                        columns={loginHistroyColumns}
                        data={loginHistory}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </Sidebar>
);
};

export default Reports;
