import React, {useState,useEffect} from 'react'
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import { url } from "../../../services/api";
import { apiConnector } from "../../../services/apiConnector";

function DestinationAutoCompo({setSelectedDest,defaultDestination}) {

    const employee = useSelector((state) => state.employee);
  const headers = {
        Authorization: `Bearer ${employee.token}`,
      };
    const [destination,setDestination] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    useEffect(() => {

        const paramsCountry = {
          method: "GET",
          url: `${url}/api/destinationActive`,
          headers: headers,
        };

        apiConnector(paramsCountry)
            .then((response) => {

              setDestination(response.data);   

        /*      
              const uniqueObjects = response.data.filter((obj, index, self) =>
                index === self.findIndex((o) => o.name === obj.name)
              );
              setDestination(uniqueObjects);   


              const nameCounts = response.data.reduce((acc, obj) => {
                acc[obj.name] = (acc[obj.name] || 0) + 1;
                return acc;
              }, {});
              
              const duplicateNames = Object.keys(nameCounts).filter(name => nameCounts[name] > 1);
            
              const duplicatesWithCount = Object.entries(nameCounts)
                .filter(([name, count]) => count > 1)
                .map(([name, count]) => ({ name, count }));

              duplicatesWithCount.forEach(({ name, count }) => {
                  console.log(`${name} - ${count}`);
                });

                */
                
            
            })
            .catch((error) => {
            console.error("Error fetching List : ", error);
            });

         },[]);

        useEffect(() => {
                    if (defaultDestination!=='' && defaultDestination!==null) {
                    const desti = destination.find(country => country._id === defaultDestination);
                    setSelectedCountry(desti || null);
                    }else{
                      setSelectedCountry(null);
                    }
         }, [defaultDestination, destination]);
        
  return (
    <div>
        
        {/* {selectedCountry?._id} -- {selectedCountry?.name} */}

        <Autocomplete
            value={selectedCountry}
            onChange={(event, newValue) => {
                setSelectedCountry(newValue);
                setSelectedDest(newValue ? newValue._id : null);
            }}
            options={destination}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Select Destination" />}
            />

        </div>
  )
}

export default DestinationAutoCompo