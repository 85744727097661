import { useMemo,useState,useEffect } from 'react';
import { useSelector } from "react-redux";
import { url } from "../../services/api";
import { apiConnector } from "../../services/apiConnector";
import { useNavigate } from 'react-router-dom';

//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';

//Material UI Imports
import {
  Box,
  // Button,
  ListItemIcon,
  MenuItem,
  // Typography,
  // lighten,
} from '@mui/material';

import {  Delete, Send } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import toast from "react-hot-toast";
//Icons Imports
// import { AccountCircle, Send } from '@mui/icons-material';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';





const ReqCompo = ({props}) => {
  //console.log("accountID",props?.accountIdProps);
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
           {
            accessorFn:  (row)=>  employee.role==='admin'? row?.accountId?.companyName :  row?.accountId?.ghostName ,  
            id :'companyName',
            header: 'Company Name',
            size: 30,
          },

          {
            accessorKey: 'destinationName.name', //accessorFn used to join multiple data into a single cell
            //id is still required when using accessorFn instead of accessorKey
            header: 'Destinaiton',
            size: 50,
          },
          {
            accessorKey: 'currency.name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            filterVariant: 'autocomplete',
            header: 'Currency',
            size: 50,
          },
          {
            accessorKey: 'qualityCategory.name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            filterVariant: 'autocomplete',
            header: 'Category',
            size: 10,
          },

          {
            accessorFn: (row) =>  `${row.pricingRange[0]}` , //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            id:'range1',
            header: 'From Range ',
            size: 30,
          },
          {
            accessorFn: (row) =>  `${row.pricingRange[1]}` , //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            id:'range2',
            header: 'To Range ',
            size: 30,
          },
          {
            accessorKey: 'volume',
            // filterVariant: 'range', //if not using filter modes feature, use this instead of filterFn
            filterFn: 'between',
            header: 'Volume',
            size: 3,
            //custom conditional format and styling
            Cell: ({ cell }) => (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor:
                    cell.getValue() <=0
                      ? theme.palette.error.dark
                      : cell.getValue() >= 1 && cell.getValue() < 10
                        ? theme.palette.warning.dark
                        : theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  maxWidth: '9ch',
                  p: '0.25rem',
                })}
              >
                {cell.getValue() //?.toLocaleString
                // ?.('en-US', {
                //   style: 'currency',
                //   currency: 'USD',
                //   minimumFractionDigits: 0,
                //   maximumFractionDigits: 0,
                // })
                }
              </Box>
            ),
          },
       
          {
            accessorKey: 'remarks', //hey a simple column for once
            header: 'Remarks',
            size: 30,
          },
          
          {
            accessorFn: (row) => new Date(row.updatedAt), //convert to Date for sorting and filtering
            id: 'updateDt',
            header: 'Updated Date',
            filterVariant: 'date',
            filterFn: 'lessThan',
            sortingFn: 'datetime',
            Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
            Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
            muiFilterTextFieldProps: {
              sx: {
                minWidth: '150px',
              },
            },
          },
        
    ],
    [],
  );


  const [allAccounts, setAllAccounts] = useState([]);
  const [reloadData,setReloadData] =useState(false);
  const employee = useSelector((state) => state.employee);
  const headers = {
      Authorization: `Bearer ${employee.token}`,
    };
  
  useEffect(() => {

 
      const params = {
        method: "GET",
        url: (props?.accountIdProps) ?  `${url}/api/requirements?accountId=${props?.accountIdProps}` :  `${url}/api/requirements` ,
        headers: headers,
      };
    apiConnector(params)
      .then((response) => {
        // if(employee.role==='admin')
        //   setAllAccounts(response.data);
        // else{
          //console.log("filterd ", response.data.filter((acc) => acc.accountId?._id === props?.accountIdProps))
          //setAllAccounts( response.data.filter((acc) => acc.accountId?._id === props?.accountIdProps));
          setAllAccounts(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching daily progress reports:", error);
        // setLoading(false);
      });
    }, [props?.reload,reloadData]);

  const table = useMaterialReactTable({
    columns,
    data:allAccounts, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,//showing multiple filter options
    enableColumnOrdering: true,
    enableGrouping: false,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true, // view profile others
    enableRowSelection: false,
    initialState: {
      density: 'compact',
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    
    renderRowActionMenuItems: ({ closeMenu,row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          if ( props?.openReqModalRef) { 
            props?.setReqEditData(row.original);
            props?.openReqModalRef.current.click();
           }else {
             navigate(`/account?id=${row?.original?.accountId?._id}`);
           }
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
           <Send />
          {/* <AccountCircle /> */}
        </ListItemIcon>
        
        {props?.openReqModalRef ?"Edit":"View Account"}   
           
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          // Send email logic...
          handleDeleteReq(`${row?.original?._id}`);

          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        Delete 
      </MenuItem>,
    ],
    // renderTopToolbar: ({ table }) => {
    //   const handleDeactivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('deactivating ' + row.getValue('name'));
    //     });
    //   };

    //   const handleActivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('activating ' + row.getValue('name'));
    //     });
    //   };

    //   const handleContact = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('contact ' + row.getValue('name'));
    //     });
    //   };

    //   return (
    //     <Box
    //       sx={(theme) => ({
    //         backgroundColor: lighten(theme.palette.background.default, 0.05),
    //         display: 'flex',
    //         gap: '0.5rem',
    //         p: '8px',
    //         justifyContent: 'space-between',
    //       })}
    //     >
    //       <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            
    //         <MRT_GlobalFilterTextField table={table} />
    //         <MRT_ToggleFiltersButton table={table} />
    //       </Box>
    //       <Box>
    //         <Box sx={{ display: 'flex', gap: '0.5rem' }}>
    //           <Button
    //             color="error"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleDeactivate}
    //             variant="contained"
    //           >
    //             Deactivate
    //           </Button>
    //           <Button
    //             color="success"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleActivate}
    //             variant="contained"
    //           >
    //             Activate
    //           </Button>
    //           <Button
    //             color="info"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleContact}
    //             variant="contained"
    //           >
    //             Contact
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Box>
    //   );
    // },
  });


  const handleDeleteReq = async(idToDelete)=>{
      const confirm = window.confirm("Confirm to delete the selected Requirment?")
      if(!confirm)
        return false;
    try {
      const response = await apiConnector({
        method: "DELETE",
        url: `${url}/api/requirements/${idToDelete}`,  
        headers: headers,
      });

      // Check if the API call was successful
      if (response.status === 200) {
        toast.success(`${response?.data?.message}`);
        setReloadData(!reloadData);
      }else{
        toast.error("Some error occured!")
      }
    } catch (error) {
      toast.error(`Error : ${error?.response?.data?.message}`);
    }
  }

  return <MaterialReactTable table={table} />;
};

//Date Picker Imports - these should just be in your Context Provider
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const ExampleWithLocalizationProvider = (accountIdProps) => (
  //App.tsx or AppProviders file

  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ReqCompo props={accountIdProps} />
  </LocalizationProvider>
);

export default ExampleWithLocalizationProvider;

// function useGetAccounts() {
//     const employee = useSelector((state) => state.employee);
//     const headers = {
//         Authorization: `Bearer ${employee.token}`,
//       };
//     return useQuery({
//       queryKey: ['users'],
//       queryFn: async () => {
//         // Function to fetch users data
//         const fetchUsersData = () => {
//           const params = {
//             method: "GET",
//             url: `${url}/api/account`,
//             headers: headers,
//           };
//           // Returning a promise for the API call
//           return apiConnector(params);
//         };
  
//         try {
//           // Call the function to fetch users data
//           const response = await fetchUsersData();
//     //       roleFilter
//     // ? response.data.filter((user) => user.role === "NOC Manager")
//     // : response.data;
//         // if("NOC Manager"===filterRole)
//         // {
//           // return response.data.filter((user) => user.role === "NOC Manager");

//         // }
//         // else
//           // return response.data.filter((user) => user.role === filterRole);
//           return response.data;
//         } catch (error) {
//           // Handle error
//           console.error("Error fetching Users:", error);
//           throw new Error(error); // Rethrow the error to be handled by useQuery
//         }
//       },
//       refetchOnWindowFocus: false,
//     });
//   }

 