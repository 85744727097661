import { useMemo,useState,useEffect } from 'react';
import { useSelector } from "react-redux";
import { url } from "../../services/api";
import { apiConnector } from "../../services/apiConnector";
import { useNavigate } from 'react-router-dom';

//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';

//Material UI Imports
import {
  Box,
  // Button,
  ListItemIcon,
  MenuItem,
  Typography,
  // lighten,
} from '@mui/material';

import { AccountCircle, Send } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import toast from 'react-hot-toast';

 
const AccountsComp = ({loadingVar,myorAll}) => {
 
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        id: 'accounts', //id used to define `group` column
        header: 'Account Details',
        columns: [
          {
            accessorKey: 'name',  
             header: 'Account Name',
            size: 30,
            Cell: ({ row ,cell}) => (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor:
                    row?.original.status ===false
                      ? theme.palette.error.dark :'',
                  borderRadius: '0.25rem',
                  color:   row?.original.status ===true
                  ?'' :'#fff', 
                 // maxWidth: '9ch',
                  p: '0.25rem',
                })}
              >
                {cell.getValue()  }
              </Box>
            ),

          },
          {
            accessorFn: (row) => employee.role==='admin'? row?.companyName :  row?.ghostName  ,
            id :"companyName",
            enableClickToCopy: true,
            filterVariant: 'autocomplete',
            header: 'Company Name',
            size: 50,
          },
          {
           // accessorKey: 'email',  
            accessorFn: (row) => employee.role==='admin'? row?.email :  'xxxxxxxxxxxx'  ,
            id:'email',
            enableClickToCopy: true,
            filterVariant: 'autocomplete',
            header: 'Email',
            size: 50,
          },
          {
            accessorFn: (row) => employee.role==='admin'? row?.phone :  'xxxxxxxxxxxx'  ,
            id:'phone',
            //accessorKey: 'phone',  
            enableClickToCopy: true,
            filterVariant: 'autocomplete',
            header: 'Phone',
            size: 10,
          },
          {
            accessorKey: 'accountType',  
            // filterVariant: 'autocomplete',
            header: 'Type',
            size: 10,
          },
          {
            accessorFn: (row) =>  !row.assignedTo?.name ? "NOT ASSGINED" : row.assignedTo.name , //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            id:'assignedTo',
            enableClickToCopy: true,
            header: 'Assigned AM',
            size: 20,
          },
          
        ],
      },
      {
        id: 'sericeORreq',
        header: 'Services/Requirments Count',
        columns: [
          {
            accessorKey: 'servicesCount',
            filterFn: 'between',
            header: 'Services',
            size: 3,
            //custom conditional format and styling
            Cell: ({ cell }) => (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor:
                    cell.getValue() <=0
                      ? theme.palette.error.dark
                      : cell.getValue() >= 1 && cell.getValue() < 10
                        ? theme.palette.warning.dark
                        : theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                 // maxWidth: '9ch',
                  p: '0.25rem',
                })}
              >
                {cell.getValue() }
              </Box>
            ),
          },
          {
            accessorKey: 'reqCount',
            filterFn: 'between',
            header: 'Req',
            size: 3,
            Cell: ({ cell }) => (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor:
                    cell.getValue() <=0
                      ? theme.palette.error.dark
                      : cell.getValue() >= 1 && cell.getValue() < 10
                        ? theme.palette.warning.dark
                        : theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                 // maxWidth: '9ch',
                  p: '0.25rem',
                })}
              >
                {cell.getValue()      }
              </Box>
            ),
          },
           
          
          {
            accessorFn: (row) => new Date(row.createdAt), //convert to Date for sorting and filtering
            id: 'updateDt',
            header: 'Updated Date',
            filterVariant: 'datetime-range',
           // filterFn: 'lessThan',
            //sortingFn: 'datetime',
            Cell: ({ cell }) => `${cell.getValue().toLocaleDateString()} ${cell
              .getValue()
              .toLocaleTimeString()}`,//cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
            Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
            muiFilterTextFieldProps: {
              sx: {
                minWidth: '150px',
              },
            },
          },
          {
            accessorFn: row=> row.updatedBy?.name,
            id:'updatedBy',
            header :'updatedBy',
            size:20
          },
          {
            accessorKey:'createdBy.name',
            header :'CreatedBy',
            size:20
          },

          {
            accessorKey: 'status',
            header: 'Status',
            size: 3,
            Cell: ({ cell }) => (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor:
                    cell.getValue() ===false
                      ? theme.palette.error.dark : theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                 // maxWidth: '9ch',
                  p: '0.25rem',
                })}
              >
                {cell.getValue() ?"ENABLED":"DISABLED"}
              </Box>
            ),
          },

          

        ],
      },
    ],
    [],
  );


  const [allAccounts, setAllAccounts] = useState([]);

  const employee = useSelector((state) => state.employee);
  // console.log(employee);
  const headers = {
      Authorization: `Bearer ${employee.token}`,
    };
  useEffect(() => { 
      const params = {
        method: "GET",
        url: `${url}/api/account`,
        headers: headers,
      };
    apiConnector(params)
      .then((response) => {
        // setAllAccounts( response.data.filter((acc) => acc.assignedTo?._id === employee._id));
       if(myorAll==="my")
           setAllAccounts( response.data.filter((acc) => acc.assignedTo?._id === employee._id));
        else
          setAllAccounts(response.data);
       
      })
      .catch((error) => {
        console.error("Error fetching daily progress reports:", error);
        // setLoading(false);
      });
    }, [loadingVar?.loadingVar]);

  const table = useMaterialReactTable({
    columns,
    data:allAccounts, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,//showing multiple filter options
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true, // view profile others
    enableRowSelection: false,
    initialState: {
      density: 'compact',
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-around',
          left: '30px',
          maxWidth: '1000px',
          position: 'sticky',
          width: '100%',
        }}
      >
         
        <Box sx={{  display: '' , textAlign: '' }}>
          <Typography variant="mr-5">Address:  <b>   {row.original?.address}, {row.original?.country?.name} </b>  </Typography> &nbsp; &nbsp; &nbsp;
          <Typography variant="mr-5">Company  Name:   <b>  {row.original?.ghostName} </b> </Typography>&nbsp; &nbsp; &nbsp; <br/><br/> <hr/>
            <b><u> Billing Details </u> </b> <br/>  <br/> 
          <Typography variant="mr-5">Billing Email:  <b>   { employee.role ==='admin'  ?  row.original?.billingEmail :'XXXXXXXXXXXX'   }</b>  </Typography> &nbsp; &nbsp; &nbsp;
           <Typography variant="mr-5">Sales Email:  <b>  {   employee.role ==='admin'  ?  row.original?.salesEmail : 'XXXXXXXXXXXX' }</b>  </Typography> &nbsp; &nbsp; &nbsp;
          <Typography variant="mr-5">Currency:   <b>  {row.original?.currency?.name} </b> </Typography>&nbsp; &nbsp; &nbsp; <br/> <br/> <hr/>
         { row.original?.assignedTo && <Typography variant="mr-5">AM Details:   <b>  {row.original?.assignedTo?.name}, {row.original?.assignedTo?.role} , Email : {row.original?.assignedTo?.email}</b> </Typography>}
        
        </Box>
      </Box>
    ),
    renderRowActionMenuItems: ({ closeMenu,row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          navigate(`/account?id=${row?.original?._id}`);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
           View Account 
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          if(employee.role==='admin' || employee.role==='Account Manager'){ 
          loadingVar?.setAccountEditData(row?.original)
          loadingVar?.openAccountModalRef.current.click();
        }else
        toast.error("Account Edit not allowed!")

          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Send />
        </ListItemIcon>
       Edit Account
      </MenuItem>,
    ],

    //current No use 
    // renderTopToolbar: ({ table }) => {
    //   const handleDeactivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('deactivating ' + row.getValue('name'));
    //     });
    //   };

    //   const handleActivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('activating ' + row.getValue('name'));
    //     });
    //   };

    //   const handleContact = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('contact ' + row.getValue('name'));
    //     });
    //   };

    //   return (
    //     <Box
    //       sx={(theme) => ({
    //         backgroundColor: lighten(theme.palette.background.default, 0.05),
    //         display: 'flex',
    //         gap: '0.5rem',
    //         p: '8px',
    //         justifyContent: 'space-between',
    //       })}
    //     >
    //       <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
             
    //         <MRT_GlobalFilterTextField table={table} />
    //         <MRT_ToggleFiltersButton table={table} />
    //       </Box>
    //       <Box>
    //         <Box sx={{ display: 'flex', gap: '0.5rem' }}>
    //           <Button
    //             color="error"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleDeactivate}
    //             variant="contained"
    //           >
    //             Deactivate
    //           </Button>
    //           <Button
    //             color="success"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleActivate}
    //             variant="contained"
    //           >
    //             Activate
    //           </Button>
    //           <Button
    //             color="info"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleContact}
    //             variant="contained"
    //           >
    //             Contact
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Box>
    //   );
    // },
    //current No use 

  });

  return <MaterialReactTable table={table} />;
};


const ExampleWithLocalizationProvider = (loadingVar) =>
  {
    const employee = useSelector((state) => state.employee);
  return (
  

  
  <LocalizationProvider dateAdapter={AdapterDayjs}   >
    <br/> <br/>
    {employee.role!=="NOC Manager" &&   ( <> 
      <h3 className='h5 text-center text-black font-bold'> Accounts assigned to you</h3>
      <br/>  
      <AccountsComp loadingVar={loadingVar} myorAll={"my"}/> 
      <br/> <br/> </>)  }

    <h3 className='h5 text-center text-black font-bold'> All Accounts </h3>
    <br/>  
        <AccountsComp loadingVar={loadingVar}   myorAll={"all"}  /> 
  </LocalizationProvider>
)};

export default ExampleWithLocalizationProvider;




 