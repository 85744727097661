import React from 'react';
import Sidebar from '../Components/Sidebar';


const Others = () => {

    return (
        <Sidebar>
        

        <div className='container-fluid'>
       

        </div>
    


        </Sidebar>
    );
};

export default Others;