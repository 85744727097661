
import React from "react";
import Sidebar from "../Components/Sidebar";

import MapperCompo from "../Components/MapperCompo";

const Mapper = () => {
  

  return (
    <Sidebar>

        <div className="container-fluid">
          <MapperCompo/>
        </div>

  </Sidebar>
  );
};

export default Mapper;