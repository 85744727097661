import { useMemo,useState,useEffect } from 'react';
import { useSelector } from "react-redux";
import { url } from "../../services/api";
import { apiConnector } from "../../services/apiConnector";
import { useNavigate, useParams } from 'react-router-dom';
import toast from "react-hot-toast";
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';

//Material UI Imports
import {
  Box,
  // Button,
  ListItemIcon,
  MenuItem,
  // Typography,
  // lighten,
} from '@mui/material';

import { AccountCircle, Send, Delete } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const MappingNotificationCompo = (props) => {
  const navigate = useNavigate();
  const employee = useSelector((state) => state.employee);
  const headers = {
      Authorization: `Bearer ${employee.token}`,
    };

  const columns = useMemo(
    () => [
           {
            accessorKey:'message',
            header: 'Message',
            size: 30,
          },

        
          {
            accessorKey: 'emitter.name',  
            header: 'Actioned By',
            size: 20,
          },


          

         

          {
            accessorFn: (row) => new Date(row.createdAt), //convert to Date for sorting and filtering
            id: 'createdDt',
            header: 'Date',
            filterVariant: 'date',
            filterFn: 'lessThan',
            sortingFn: 'datetime',
          //  Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
            Cell: ({ cell }) => `${cell.getValue().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })} ${cell
              .getValue()
              .toLocaleTimeString()}`,
            Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
            muiFilterTextFieldProps: {
              sx: {
                minWidth: '150px',
              },
            },
          },
          {
            accessorKey: 'product',  
            header: 'Product',
            size: 20,
          },
        
    ],
    [],
  );

  const [reload,setReload]=useState(false);
  const [allAccounts, setAllAccounts] = useState([]);

  

  useEffect(() => {
      const params = {
        method: "GET",
       // url: (props?.fetchType==='ASSIGNED_TO_ME') ?  `${url}/api/routingTask/assignedTo/${employee?._id}` :  `${url}/api/routingtask` ,
       url:  `${url}/api/notification/mapper`,

        headers: headers,
      };
    apiConnector(params)
      .then((response) => {
       
          setAllAccounts(response.data);
      
      })
      .catch((error) => {
        console.error("Error fetching daily progress reports:", error);
        // setLoading(false);
      });
    }, [props?.reload,reload]);


  const table = useMaterialReactTable({
    columns,
    data:allAccounts, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,//showing multiple filter options
    enableColumnOrdering: true,
    enableGrouping: false,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true, // view profile others
    enableRowSelection: false,
    initialState: {
      density: 'compact',
      showColumnFilters: false,
      showGlobalFilter: true,
      // columnVisibility: { ghostVisibility: employee.role==='admin'?true:false , ghostPricing : employee.role==='admin'?true:false },
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
   
    muiTableBodyRowProps: ({ row ,theme}) => ({
      //conditionally style selected rows
      sx: {
        fontWeight:'bold',// row.getIsSelected() ? 'bold' : 'normal',
        backgroundColor:     '#FFFFE0'
      },
    }),
  
    renderRowActionMenuItems: ({ closeMenu,row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          navigate(`/account?id=${row?.original?.accountId}`);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
           View Account
      </MenuItem>,
      // <MenuItem
      //   key={1}
      //   onClick={() => {
      //     props?.setserviceEditData(row.original);
      //     props?.openModal.current.click();

      //     closeMenu();
      //   }}
      //   sx={{ m: 0 }}
      // >
      //   <ListItemIcon>
      //     <Send />
      //   </ListItemIcon>
      //      Edit
      // </MenuItem>,
    
  

      // <MenuItem
      // key={4}
      // onClick={() => {
      //   handleDeleteReq(`${row?.original?._id}`);
      //   closeMenu();
      // }}
      // sx={{ m: 0 }}
      // >
      // <ListItemIcon>
      //   <Delete />
      // </ListItemIcon>
      // Delete 
      // </MenuItem>,
     
    
    ],
    
  });



  

 const handleDeleteReq = async(idToDelete)=>{
      const confirm = window.confirm("Confirm to delete the selected Route?")
      if(!confirm)
        return false;
    try {
      const response = await apiConnector({
        method: "DELETE",
        url: `${url}/api/routingTask/${idToDelete}`,  
        headers: headers,
      });

      // Check if the API call was successful
      if (response.status === 200) {
        toast.success(`${response?.data?.message}`);
        setReload(!reload) ;
      }else{
        toast.error("Some error occured!")
      }
    } catch (error) {
      toast.error(`Error : ${error?.response?.data?.message}`);
    }
  }


  return (<>  

  <MaterialReactTable table={table} /> </>);
};



const ExampleWithLocalizationProvider = (accountIdProps) => (
  //App.tsx or AppProviders file
 
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MappingNotificationCompo 
    fetchType={accountIdProps?.fetchType} 
    reload={accountIdProps?.reload} openModal={accountIdProps?.openModal}
      serviceEditData={ accountIdProps?.serviceEditData} setserviceEditData={accountIdProps?.setserviceEditData}
    />
  </LocalizationProvider>
);

export default ExampleWithLocalizationProvider;


 