import "./App.css";
import React from "react";
import { useEffect } from "react";
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import "aos/dist/aos.css";
import AOS from "aos";
import Dashboard from '../src/Admin/pages/Dashboard.jsx';
import Reports from '../src/Admin/pages/Reports.jsx';
import Routess from '../src/Admin/pages/Routess.jsx';
import Tasks from '../src/Admin/pages/Tasks.jsx';
import MyTasks from '../src/Admin/pages/MyTasks.jsx';
import Notifications from '../src/Admin/pages/Notification.jsx';
import Others from '../src/Admin/pages/Others.jsx';
import Accounts from '../src/Admin/pages/Accounts.jsx'
import CustomerReq from '../src/Admin/pages/CustomerReq.jsx'
import Mapper from '../src/Admin/pages/Mapper.jsx'
import Settings from '../src/Admin/pages/Settings.jsx'
import Login from '../src/Admin/pages/Login.jsx'
import ResetPassword  from '../src/Admin/pages/ResetPassword.jsx'
import { Toaster } from "react-hot-toast";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Account from "./Admin/pages/Account.jsx";
import TaskTimeline from "./Admin/pages/TaskTimeline.jsx";
import RoutingTaskTimeline from "./Admin/pages/RoutingTaskTimeline.jsx";
import MappingNotification from "./Admin/pages/MappingNotification.jsx";



import RoutingTasks from '../src/Admin/pages/RoutingTasks.jsx';

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const ProtectedRoute = ({ children }) => {
    const employee = useSelector((state) => state.employee);
    let location = useLocation();

    if (!employee._id) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  };

  

const location = useLocation();
  return (
    <> 
    <Toaster position="top-right" reverseOrder={false} />
  
      <Routes>
        <Route path="/dashboard" element={ <ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
        <Route path="/routess" element={<ProtectedRoute><Routess /></ProtectedRoute>} />
        <Route path="/customer" element={<ProtectedRoute><CustomerReq /></ProtectedRoute>} />
        <Route path="/tasks" element={<ProtectedRoute><Tasks /></ProtectedRoute>} />
        <Route path="/mytasks" element={<ProtectedRoute><MyTasks /></ProtectedRoute>} />
        <Route path="/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
        <Route path="/others" element={<ProtectedRoute><Others /></ProtectedRoute>} />
        <Route path="/accounts" element={<ProtectedRoute><Accounts/></ProtectedRoute>}/>
        <Route path="/mapper" element={<ProtectedRoute><Mapper/></ProtectedRoute>}/>
        <Route path="/settings" element={<ProtectedRoute><Settings/></ProtectedRoute>}/>
        <Route path="/account" element={<ProtectedRoute><Account/></ProtectedRoute>}/>
        <Route path="/taskTimeline" element={<ProtectedRoute><TaskTimeline/></ProtectedRoute>}/>
        <Route path="/routingTaskThread" element={<ProtectedRoute><RoutingTaskTimeline/></ProtectedRoute>}/>        
        <Route path="/routingtasks" element={<ProtectedRoute><RoutingTasks /></ProtectedRoute>} />
        <Route path="/mappingHistory" element={<ProtectedRoute><MappingNotification /></ProtectedRoute>} />

        
      <Route path="/" element={<Login/>}/>
      <Route path="/reset-password" element={<ResetPassword/>}/>
      </Routes>

   </>
  );
}

export default App;
