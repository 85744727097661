import React, {useState,useEffect} from 'react'
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import { url } from "../../../services/api";
import { apiConnector } from "../../../services/apiConnector";

function AutoCompleteCompo({setenteredCompany,defaultValue}) {

    const employee = useSelector((state) => state.employee);
  const headers = {
        Authorization: `Bearer ${employee.token}`,
      };
    const [nameList,setNameList] = useState([]);
    const [selected, setSelected] = useState('');
    
    useEffect(() => {

      const paramsCompanyName = {
        method: "GET",
        url: `${url}/api/account`,
        headers: headers,
      };

    apiConnector(paramsCompanyName)
        .then((response) => {
          setNameList(response.data.map(item=> item.ghostName));   
        })
        .catch((error) => {
          console.error("Error fetching List : ", error);
        });

         },[]);

        useEffect(() => {
            console.log(defaultValue) ;
                    if (defaultValue!=='' && defaultValue!==null) {
                      setSelected(defaultValue);
                    }else{
                      setSelected('');
                    }
         }, [defaultValue]);

         const handleInputChange = (event, newValue) => {
          setenteredCompany(newValue);
          setSelected(newValue);
        };
        
  return (
    <div>
        
        {/* {selectedCountry?._id} -- {selectedCountry?.name} */}

        <Autocomplete
            value={selected}
            onChange={(event, newValue) => {
              setSelected(newValue);
               
            }}
            onInputChange={handleInputChange}
            freeSolo
            options={nameList}
            // getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Select Company" />}
            />

        </div>
  )
}

export default AutoCompleteCompo