import { useMemo,useState,useEffect ,useRef} from 'react';
import { useSelector } from "react-redux";
import { url } from "../../services/api";
import { apiConnector } from "../../services/apiConnector";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus,} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import Loader from "../../components/loader/loader";
import AutoCompleteCompo from './reusable/AutoCompleteCompo';
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';

//Material UI Imports
import {
  Box,
  // Button,
  ListItemIcon,
  MenuItem,
  Typography,
  // lighten,
} from '@mui/material';

import { AccountCircle, 
  // Send 
} from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DestinationAutoCompo from './reusable/DestinationAutoCompo';

 
const ReportsCompo = (props) => {
  
  // const navigate = useNavigate();
  const columns = useMemo(
    () => [
     
       
      {
        accessorFn: (row) => new Date(row.updatedAt), //convert to Date for sorting and filtering
        id: 'updateDt',
        header: 'Updated Date',
        filterVariant: 'datetime-range',
       
        Cell: ({ cell }) => `${cell.getValue().toLocaleDateString()} ${cell
          .getValue()
          .toLocaleTimeString()}`,//cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        muiFilterTextFieldProps: {
          sx: {
            minWidth: '150px',
          },
        },
      },
      
       
      {
        accessorFn: (row) => new Date(row.createdAt), //convert to Date for sorting and filtering
        id: 'CreatedDt',
        header: 'Created Date',
        filterVariant: 'datetime-range',
       
        Cell: ({ cell }) => `${cell.getValue().toLocaleDateString()} ${cell
          .getValue()
          .toLocaleTimeString()}`,//cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        muiFilterTextFieldProps: {
          sx: {
            minWidth: '150px',
          },
        },
      },
          
      {
        accessorKey: 'companyName',
        header: 'Company Name',
        size: 150,
      },
      {
        accessorKey: 'destination.name',
        header: 'Destination',
        size: 200,
      },
      {
        accessorKey: 'type',
        header: 'Type(Vendor/Client)',
        size: 10,
      },
      {
        accessorKey: 'remark', 
        header: 'Remarks',
        size: 200,
      },
      {
        accessorKey: 'createdBy.name', 
        header: 'Employee',
        size: 20,
      },
      {
        accessorKey: 'createdBy.role', 
        header: 'empRole',
        size: 10,
      },
    ],
    [],
  );

  const [allAccounts, setAllAccounts] = useState([]);

  const employee = useSelector((state) => state.employee);

  const headers = {
      Authorization: `Bearer ${employee.token}`,
    };
  useEffect(() => { 
      const params = {
        method: "GET",
        url:  employee.role === "admin" 
        ? `${url}/api/report/fetchAllReports`
        : `${url}/api/report/fetchMyReports`,
        headers: headers,
      };
    apiConnector(params)
      .then((response) => {
          setAllAccounts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching daily progress reports:", error);
        // setLoading(false);
      });
    }, [props?.loadingVar]);

  const table = useMaterialReactTable({
    columns,
    data:allAccounts, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,//showing multiple filter options
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true, // view profile others
    enableRowSelection: false,
    initialState: {
      density: 'compact',
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    // renderDetailPanel: ({ row }) => (
    //   <Box
    //     sx={{
    //       alignItems: 'center',
    //       display: 'flex',
    //       justifyContent: 'space-around',
    //       left: '30px',
    //       maxWidth: '1000px',
    //       position: 'sticky',
    //       width: '100%',
    //     }}
    //   >
         
    //     <Box sx={{  display: '' , textAlign: '' }}>
    //       <Typography variant="mr-5">Remarks:  <b>   {row.original?.remark} </b>  </Typography> &nbsp; &nbsp; &nbsp;
         
    //     </Box>
    //   </Box>
    // ),
    renderRowActionMenuItems: ({ closeMenu,row }) => [
      <MenuItem
        key={0}
        onClick={() => {
       //   props?.setSelectedDest(row?.original?.destination?._id)
          props?.setReportEditData(row?.original);
        //  props?.setenteredCompany(row?.original?.companyName);
          props?.openModalRef.current.click();
          //navigate(`/account?id=${row?.original?._id}`);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
           Edit 
      </MenuItem>,
      // <MenuItem
      //   key={1}
      //   onClick={() => {
         
         
      //     closeMenu();
      //   }}
      //   sx={{ m: 0 }}
      // >
      //   <ListItemIcon>
      //     <Send />
      //   </ListItemIcon>
      //  Edit Account
      // </MenuItem>,
    ],

    //current No use 
    // renderTopToolbar: ({ table }) => {
    //   const handleDeactivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('deactivating ' + row.getValue('name'));
    //     });
    //   };

    //   const handleActivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('activating ' + row.getValue('name'));
    //     });
    //   };

    //   const handleContact = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('contact ' + row.getValue('name'));
    //     });
    //   };

    //   return (
    //     <Box
    //       sx={(theme) => ({
    //         backgroundColor: lighten(theme.palette.background.default, 0.05),
    //         display: 'flex',
    //         gap: '0.5rem',
    //         p: '8px',
    //         justifyContent: 'space-between',
    //       })}
    //     >
    //       <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
             
    //         <MRT_GlobalFilterTextField table={table} />
    //         <MRT_ToggleFiltersButton table={table} />
    //       </Box>
    //       <Box>
    //         <Box sx={{ display: 'flex', gap: '0.5rem' }}>
    //           <Button
    //             color="error"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleDeactivate}
    //             variant="contained"
    //           >
    //             Deactivate
    //           </Button>
    //           <Button
    //             color="success"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleActivate}
    //             variant="contained"
    //           >
    //             Activate
    //           </Button>
    //           <Button
    //             color="info"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleContact}
    //             variant="contained"
    //           >
    //             Contact
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Box>
    //   );
    // },
    //current No use 

  });

  return <MaterialReactTable table={table} />;
};


const ExampleWithLocalizationProvider = (loadingVar) =>
  {
    const employee = useSelector((state) => state.employee);
    const headers = {
      Authorization: `Bearer ${employee.token}`,
    };
    const [loading, setLoading] = useState();
    const closeRef = useRef();
    const resetFormRef =useRef();
    const openModalRef=useRef();
    const [contentReload, setContentReload] = useState();

   
    // const [companyName,setCompanyName]=useState([]); //used for default value
    const [enteredCompany,setenteredCompany]=useState(); //used for getting from autocompleted
    // const [defaultCompanyName,setDefaultCompanyName]=useState();

    const [selectedDest, setSelectedDest]=useState('');
    const [reportEditData,setReportEditData]=useState();
    // console.log(reportEditData);
    // const [defaultDestination,setDefaultDestination]=useState('');//used for default value

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const fordta = new FormData(e.currentTarget);
      const type = fordta.get('type');
      const remarks = fordta.get('remark')
      if(!enteredCompany || enteredCompany.trim()===''){
        toast.error("Company Name required!")
        setLoading(false);
        return false;
      }
      if(!selectedDest || selectedDest.trim()===''){
        toast.error("Destination Required Name required!")
        setLoading(false);
        return false;
      }
      if(!type || type.trim()===''){
        toast.error("Type required! -- ",type)
        setLoading(false);
        return false;
      }
      if(!remarks || remarks.trim()===''){
        toast.error("Remarks  required!")
        setLoading(false);
        return false;
      }
       
      try {
        let response = await apiConnector({
          method: (reportEditData && reportEditData?._id)? "PUT": "POST",
          url: (reportEditData && reportEditData?._id)? `${url}/api/report/report/${reportEditData._id}`:`${url}/api/report/report`,
          bodyData: {
            companyName: enteredCompany,
            destination:  selectedDest,
            type: type,
            remark: remarks,
          },
          headers
        });
        if (response?.status ===201) {
            toast.success('Report Added Successfully');
            closeRef.current.click();
            setContentReload(!contentReload);
        } else if (response?.status ===200) {
          toast.success('Report updated Successfully');
          closeRef.current.click();
          setContentReload(!contentReload);
      }else{
            toast.error("Error : " , response?.message);
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        }
      }
      setLoading(false);
    };
  


    // useEffect(()=>{
    // const paramsCompanyName = {
    //     method: "GET",
    //     url: `${url}/api/account`,
    //     headers: headers,
    //   };

    // apiConnector(paramsCompanyName)
    //     .then((response) => {
    //       setCompanyName(response.data.map(item=> item.companyName));   
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching List : ", error);
    //     });

    // },[]);



    useEffect(()=>{

      if(reportEditData && reportEditData!==null && Object.keys(reportEditData).length !== 0 ){
        setSelectedDest(reportEditData?.destination?._id)
        setenteredCompany(reportEditData?.companyName)
       // setDefaultCompanyName(reportEditData?.companyName)
      }else{
        setSelectedDest('')
        setenteredCompany('');
        //setDefaultCompanyName('')
      }

    },[reportEditData])

 const handleCloseModal=()=>{
         
        resetFormRef.current.reset();
        setenteredCompany('');
        setSelectedDest('');
        // setDefaultCompanyName('');
        setReportEditData({});

 }
  
    
  return (
  <LocalizationProvider dateAdapter={AdapterDayjs}   >
                     <button
                          type="button"
                          className="btn bg-gray-100 text-sm text-black border-black"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          ref={openModalRef}
                        >
                          Add new Report
                          <FontAwesomeIcon className="ml-1" icon={faPlus} />
                        </button>

    <br/>  
        <ReportsCompo loadingVar={contentReload} 
        // setSelectedDest={setSelectedDest} 
        setReportEditData={setReportEditData} 
        // setenteredCompany={setDefaultCompanyName} 
        openModalRef={openModalRef}  /> 



                     <div
                          className="modal fade "
                          id="exampleModalCenter"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalCenterTitle"
                          aria-hidden="true"
                          data-backdrop="static"
                        
                        >
                          <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-header">
                                <h2 className="font-semibold text-xl text-gray-600">
                                  New Report Addition
                                </h2>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"  
                                  ref={closeRef }
                                  onClick={handleCloseModal}
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <form onSubmit={handleSubmit}
                              ref={resetFormRef}
                              >
                              <div className="modal-body">
                                <div className="row    p-1  flex items-center justify-center ">
                                  <div className="container  ">
                                    <div>
                                      <div className="bg-white rounded shadow-lg p-4 px-4 md:p-2 mb-6">
                                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                          <div className="lg:col-span-4">
                                            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                             

                                              <div className="md:col-span-5">
                                                <label htmlFor="company_name">
                                                  Company Name
                                                </label>
                                                {/* values - {enteredCompany} */}
                                                <AutoCompleteCompo   defaultValue={enteredCompany} setenteredCompany={setenteredCompany}/>
                                                {/* <AutoCompleteCompo value={companyName} defaultValue={enteredCompany} setenteredCompany={setenteredCompany}/> */}

                                              </div>

                                              <div className=" md:col-span-5">
                                                <label htmlFor="destination">
                                                  Destination
                                                </label>
                                                {/* values - {selectedDest} */}
                                                <DestinationAutoCompo defaultDestination={selectedDest} setSelectedDest={setSelectedDest}   />
                                                {/* <input
                                                  name="destination"
                                                  onChange={changeHandler}
                                                  value={formData.destination}
                                                  type="text"
                                                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                                /> */}

                                      {/* <select   name="destinationName"  className="form-control" >
                                          <option value="">
                                            Select Country
                                          </option>
                                          {country.map((ctry) => (
                                            <option
                                              key={ctry._id}
                                              value={ctry._id}
                                            
                                            >
                                              {ctry.name}
                                            </option>
                                          ))}
                                        </select> */}

                                           
                                              </div>

                                              <div className="md:col-span-5">
                                                <label htmlFor="address">
                                                  Type
                                                </label>

                                                <select
                                                  name="type"
                                                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                                  // onChange={changeHandler}
                                                  // value={formData.type}
                                                >
                                                  <option value="" className="">
                                                    Select Type
                                                  </option>
                                                  <option selected={reportEditData && reportEditData?.type==="vendor"} value="vendor">
                                                    Vendor
                                                  </option>
                                                  <option selected={reportEditData && reportEditData?.type==="customer"}  value="customer">
                                                    Customer
                                                  </option>
                                                </select>
                                              </div>

                                              <div className="md:col-span-5">
                                                <label htmlFor="remarks">
                                                  Remarks
                                                </label>

                                                <textarea
                                                  name="remark"
                                                  // onChange={changeHandler}
                                                  // value={formData.remark}
                                                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                                  rows="4"
                                                  defaultValue={reportEditData && reportEditData?.remark ? reportEditData?.remark : ''}
                                                ></textarea>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                           
                                <div className="modal-footer md:col-span-7 flex justify-between  ">
                                  <button
                                    type="button"
                                    className=" bg-red-600 mr-auto text-md flex justify-content  text-white rounded px-4 py-1 font-medium"
                                    data-dismiss="modal"
                                    onClick={handleCloseModal}
                                  >
                                    
                                    Close
                                  </button>

                                  <button
                                    className=" text-md bg-blue-400 flex justify-content  text-white rounded px-4 py-1 font-medium"
                                    type="submit" 
                                  >
                                    
                                    {loading ? <Loader /> : "SUBMIT"}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

  </LocalizationProvider>
)};

export default ExampleWithLocalizationProvider;




 